/* eslint-disable no-loop-func */
import * as auth0 from "auth0-js";
import { AUTH_CONFIG } from "./auth0-variables";

export default class Auth {
  public auth0: auth0.WebAuth;
  public profile: auth0.Auth0UserProfile;
  public idToken: string;
  public accessToken: string;
  public expiresAt: number;

  constructor() {
    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.login = this.login.bind(this);
    this.loginSAML = this.loginSAML.bind(this);
    this.loginSAMLNA = this.loginSAMLNA.bind(this);
    this.loginSAMLProdNA = this.loginSAMLProdNA.bind(this);
    this.loginSAMLProdAU = this.loginSAMLProdAU.bind(this);
    this.loginSAMLDevAU = this.loginSAMLDevAU.bind(this);
    this.loginSAMLProdAsia = this.loginSAMLProdAsia.bind(this);


    //For new CTM portal AU:
    this.loginSAMLPrtlProdAU = this.loginSAMLPrtlProdAU.bind(this);
    this.loginSAMLPrtlQaAU = this.loginSAMLPrtlQaAU.bind(this);
    this.loginSAMLPrtlDevAU = this.loginSAMLPrtlDevAU.bind(this);


    this.logout = this.logout.bind(this);
    this.setSession = this.setSession.bind(this);
    const arr = window.location.href.split("/");
    const redirectUri = arr[0] + "//" + arr[2] + "/callback";
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      audience: AUTH_CONFIG.audience,
      clientID: AUTH_CONFIG.clientId,
      domain: AUTH_CONFIG.domain,
      redirectUri, // AUTH_CONFIG.callbackUrl,
      responseType: "token id_token",
      scope: AUTH_CONFIG.scope
    });
    this.profile = {} as auth0.Auth0UserProfile;
    this.idToken = "";
    this.accessToken = "";
    this.expiresAt = 0;
  }

  public getProfile() {
    return this.profile;
  }

  public async handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err:any, authResult:any) => {
        if (err) {
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve(authResult);
      });
    });
  }

  public isAuthenticated() {
    return new Date().getTime() < (this.expiresAt as number);
  }

  public async login() {
    await this.auth0.authorize();
  }
  public async loginSAML() {
    return await this.auth0.authorize({
      connection: AUTH_CONFIG.samlConnection
    });
  }
  public async loginSAMLNA() {
    return await this.auth0.authorize({
      connection: AUTH_CONFIG.samlConnectionNA
    });
  }
  public async loginSAMLProdAU() {
    return await this.auth0.authorize({
      connection: AUTH_CONFIG.samlConnectionProdAu
    });
  }
  public async loginSAMLProdNA() {
    return await this.auth0.authorize({
      connection: AUTH_CONFIG.samlConnectionProdNA
    });
  }
  public async loginSAMLDevAU() {
    return await this.auth0.authorize({
      connection: AUTH_CONFIG.samlConnectionDevAU
    });
    }
    public async loginSAMLProdAsia() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionProdAsia
        });
    }

    //For new CTM portal AU:

    public async loginSAMLPrtlProdAU() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionPrtlProdAu
        });
    }

    public async loginSAMLPrtlQaAU() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionPrtlQaAu
        });
    }

    public async loginSAMLPrtlDevAU() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionPrtlDevAu
        });
    }



  public async logout() {
    // clear id token and expiration
    this.idToken = "";
    this.accessToken = "";
    this.expiresAt = 0;
    this.profile = {} as auth0.Auth0UserProfile;
    await this.auth0.logout({
      returnTo: window.location.origin
    });
  }

  public setSession(authResult: auth0.Auth0DecodedHash) {
    this.idToken = authResult.idToken as string;
    this.accessToken = authResult.accessToken as string;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt =
      (authResult.expiresIn as number) * 1000 + new Date().getTime();
  }
}
