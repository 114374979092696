import * as React from "react";

export interface INavbarProps {
  children: React.ReactNode;
}

const Navbar: React.SFC<INavbarProps> = (props: INavbarProps) => {
  const { children } = props;
  return (
    <header className="navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar">
      {children}
    </header>
  );
};

export default Navbar;
