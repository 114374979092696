import * as React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

const HelpdeskCard = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle>Helpdesk Support</CardTitle>
        <CardText>
          For any further support please email{" "}
          <a href="mailto:smartdata@travelctm.com">HelpDesk</a>.
        </CardText>
      </CardBody>
    </Card>
  );
};

export default HelpdeskCard;
