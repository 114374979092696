import * as React from "react";
import { Badge, Label } from "reactstrap";
import IPbiDashboard from "../../models/IPbiDashboard";

export interface IDashboardsBadgeProps {
  dashboardIds: number[];
  dashboards: IPbiDashboard[];
}

const DashboardsBadge = (props: IDashboardsBadgeProps) => {
  const { dashboardIds, dashboards } = props;
  return (
    <React.Fragment>
      <Label for="grpSelectedDashboards">Currently Selected Dashboards:</Label>
      <div id="grpSelectedDashboards">
        {dashboardIds.map(id => (
          <Badge key={id} style={{ margin: 4, padding: 4 }}>
            {
              (dashboards.find(dashboard => dashboard.id === id) as IPbiDashboard)
                .displayName
            }
          </Badge>
        ))}
      </div>
      {dashboardIds.length === 0 && <div>None</div>}
    </React.Fragment>
  );
};

export default DashboardsBadge;
