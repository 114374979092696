import * as React from "react";
import NavbarBrand from "../atoms/NavbarBrand";
import Span from "../atoms/Span";

export interface ILogoProps {
  to: string;
}

const Logo: React.SFC<ILogoProps> = (props: ILogoProps) => {
  const { to } = props;
  // tslint:disable-next-line:no-consolew
  return (
    <NavbarBrand to={to}>
      <React.Fragment>
        <Span
          style={{
            color: "#FFF",
            fontSize: "1.5rem",
            paddingRight: "4em"
          }}
        >
          <React.Fragment>
            <strong>CTM SMART</strong> Data
          </React.Fragment>
        </Span>
      </React.Fragment>
    </NavbarBrand>
  );
};

export default Logo;
