import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, ListGroup, ListGroupItem } from "reactstrap";
import ListGroupItemHeading from "reactstrap/lib/ListGroupItemHeading";
import ISqlReport from "../../models/ISqlReport";
import { IStore } from "../../models/IStore";
import paths from "../../constants";

export interface IUserReportsCardProps {
  reports: ISqlReport[];
}

const UserReportsCard: React.SFC<IUserReportsCardProps> = (
  props: IUserReportsCardProps
) => {
  const { reports } = props;
  return (
    <React.Fragment>
      <Card>
        <ListGroup>
          <ListGroupItemHeading style={{ backgroundColor: "white" }}>
            Your Reports
          </ListGroupItemHeading>
          {reports.map(report => (
            <ListGroupItem action={true} variant="Light" key={report.id}>
              <Link to={paths.sqlReports.concat(report.id.toString())}>
                {report.displayName}
              </Link>
            </ListGroupItem>
          ))}
          {(reports === undefined ||
            (reports !== undefined && reports.length === 0)) && (
            <span>You have no available reports</span>
          )}
        </ListGroup>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    reports: state.userReports
  };
};

export default connect(mapStateToProps)(UserReportsCard);
