import * as React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

const UserManualCard = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle>User Manual</CardTitle>
        <CardText>
          To read the user manual please click{" "}
          <a
            href="https://app.box.com/s/29owx8oesg5hk1hp083qr9o6bo6fcfq7"
            target="_new"
          >
            here
          </a>
          .
        </CardText>
      </CardBody>
    </Card>
  );
};

export default UserManualCard;
