import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import {
  handleDeletePredicate,
  handleLoadPredicates,
  handleUpdatePredicate
} from "../../../actions/predicates";
import AddTextField from "../../../components/molecules/AddTextField";
import PredicateDetailsTable from "../../../components/molecules/PredicateDetailsTable";
import { paths, settings } from "../../../constants";
import IPredicate from "../../../models/IPredicate";
import { IStore } from "../../../models/IStore";
import { validatePredicateName, validatePredicateDisplayName } from "../../../utils";
export interface IEditPredicatePageProps extends RouteComponentProps {
  accessToken: string;
  predicate: IPredicate;
  existingPredicates: IPredicate[];
  onHandleLoadPredicates: (accessToken: string) => void;
  onHandleUpdatePredicate: (accessToken: string, predicate: IPredicate) => void;
  onHandleDeletePredicate: (accessToken: string, id: number) => void;
}
export interface IEditPredicatePageState {
  editModeOn: boolean;
  displayName: string;
  name: string;
  isKpiGrouping: boolean;
  isEnabled: boolean;
  showDeleteModal: boolean;
  isReadyToSave: boolean;
}

class EditPredicatePage extends React.Component<
  IEditPredicatePageProps,
  IEditPredicatePageState
> {
  public isLoaded = false;
  public state = {
    editModeOn: false,
    displayName: "",
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    isKpiGrouping: false,
    isEnabled: true,
    showDeleteModal: false,
    isReadyToSave: false
  };
  constructor(props: IEditPredicatePageProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.delete = this.delete.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
    this.isKpiGroupingChange = this.isKpiGroupingChange.bind(this);
  }
  public isKpiGroupingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isKpiGrouping = e.target.checked;
    this.setState(() => ({ isKpiGrouping, isReadyToSave: true }));
  };
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public save = (e: React.MouseEvent) => {
    e.preventDefault();
    const {
      accessToken,
      onHandleUpdatePredicate,
      history,
      predicate
    } = this.props;
    const { displayName, name, isEnabled, isKpiGrouping, isReadyToSave } = this.state;
    const updatedPredicate = {
      id: predicate.id,
      displayName,
      name,
      // tslint:disable-next-line:object-literal-sort-keys
      isKpiGrouping,
      isEnabled
    } as IPredicate;
    if (this.readyToSave() && isReadyToSave) {
      onHandleUpdatePredicate(accessToken, updatedPredicate);
      history.push(paths.home);
    }
  };
  public delete = (e: React.MouseEvent) => {
    const {
      accessToken,
      predicate,
      history,
      onHandleDeletePredicate
    } = this.props;
    onHandleDeletePredicate(accessToken, predicate.id);
    history.push(paths.home);
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public toggleEdit() {
    this.setState({ editModeOn: !this.state.editModeOn });
  }
  public toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  public readyToSave = () => {
    const { displayName, name } = this.state;
    if (name === undefined || name === "" || !validatePredicateName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (displayName===undefined || displayName==="" || !validatePredicateDisplayName(displayName)){
      this.setState(()=> ({isReadyToSave:false}))
      return false;
    }
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadPredicates,
      existingPredicates,
      predicate
    } = this.props;
    const { displayName, name, isKpiGrouping, isEnabled } = predicate;
    this.setState(() => ({ displayName, name, isKpiGrouping, isEnabled }));
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingPredicates.length === 0) {
        onHandleLoadPredicates(accessToken);
      }
    }
  }
  public render() {
    const { predicate, existingPredicates } = this.props;
    const {
      editModeOn,
      displayName,
      name,
      isKpiGrouping,
      isEnabled,
      isReadyToSave
    } = this.state;
    const { allowDisablePredicates } = settings;
    return (
      <Form>
        <Modal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete Predicate {name}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete Predicate: {name}
            {"?"}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Confirm Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Card>
          <CardHeader>Predicate Details for {name}</CardHeader>
          <CardBody>
            {!editModeOn && <PredicateDetailsTable predicate={predicate} />}
            {editModeOn && (
              <React.Fragment>
                <AddTextField
                  noDuplicates={true}
                  isValid={validatePredicateName}
                  title="Name"
                  value={name}
                  existingValues={existingPredicates.map(pred => pred.name)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (n: string) => {
                    await this.setState(() => ({ name: n }));
                    this.readyToSave();
                  }}
                />
                <AddTextField
                  noDuplicates={true}
                  isValid={validatePredicateDisplayName}
                  title="Display Name"
                  value={displayName}
                  existingValues={existingPredicates.map(pred => pred.displayName)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (dn: string) => {
                    await this.setState(() => ({ displayName: dn }));
                    this.readyToSave();
                  }}
                />
                <Table>
                  <tbody>
                    <tr>
                      <th scope="row">Is KPI Grouping</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isKpiGrouping}
                          onChange={this.isKpiGroupingChange}
                        />
                        {isKpiGrouping}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {allowDisablePredicates && (
                  <Table>
                    <tr>
                      <th scope="row">Enabled</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isEnabled}
                          onChange={this.isEnabledChange}
                        />
                        {isEnabled}
                      </td>
                    </tr>
                  </Table>
                )}
              </React.Fragment>
            )}
          </CardBody>
          <CardFooter>
            {!editModeOn && (
              <React.Fragment>
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  onClick={this.toggleDeleteModal}
                  style={{
                    margin: 4,
                    padding: 4
                  }}
                >
                  <strong>Delete Predicate</strong>
                </Button>
              </React.Fragment>
            )}
            {editModeOn && (
              <React.Fragment>
                {isReadyToSave && (
                  <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
                    Save Changes
                  </Button>
                )}
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let predicate = {} as IPredicate;
  const existingPredicates = state.predicates.filter(
    pred => pred.id !== parseInt((match.params as any).id as string, 10)
  );
  if (match !== null) {
    predicate = state.predicates.find(pred => {
      return pred.id === parseInt((match.params as any).id as string, 10);
    }) as IPredicate;
  }
  return {
    accessToken: state.auth0.accessToken,
    existingPredicates,
    predicate
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadPredicates: (accessToken: string) => {
    dispatch(handleLoadPredicates(accessToken));
  },
  onHandleUpdatePredicate: (accessToken: string, predicate: IPredicate) => {
    dispatch(handleUpdatePredicate(accessToken, predicate));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleDeletePredicate: (accessToken: string, id: number) => {
    dispatch(handleDeletePredicate(accessToken, id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPredicatePage);
