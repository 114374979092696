import Report from "powerbi-report-component";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Spinner } from "reactstrap";
import {
  clearActiveDashboard,
  setActiveDashboard
} from "../../actions/activeDashboard";
import { handleRefreshToken } from "../../actions/tokenData";
import ICurrencyCode from "../../models/ICurrencyCode";
import IFYStartMonth from "../../models/IFYStartMonth";
import IPbiDashboard from "../../models/IPbiDashboard";
import { IPbiData } from "../../models/IPbiData";
import { IPbiTokenData } from "../../models/IPbiTokenData";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";
import { ITokenData } from "../../models/ITokenData";
import Jumbotron from "../atoms/Jumbotron";
import ActiveRoleBadge from "../molecules/ActiveRoleBadge";
import ResetSizeToWindowButton from "../molecules/ResetSizeToWindowButton";

export interface IDashboardViewProps {
  apiAccessToken: string;
  currencyCodes: ICurrencyCode[];
  dashboard: IPbiDashboard;
  fyStartMonths: IFYStartMonth[];
  token: ITokenData;
  activeUserRole: IRole;
  onSetActiveDashboard: (id: number) => void;
  onClearActiveDashboard: () => void;
  onHandleRefreshToken: (accessToken: string, refreshToken: string) => void;
  roleCount: number;
}

export interface IDashboardViewState {
  currency: string;
  embedUrl: string;
  fetched: boolean;
  fetching: boolean;
  fitToWindowVisible: boolean;
  fystart: number;
  height: string;
  intervalId: number;
  pbiToken: IPbiTokenData;
  // token: ITokenData;
  width: string;
}

export class DashboardView extends React.Component<
  IDashboardViewProps,
  IDashboardViewState
> {
  public state = {
    currency: "",
    embedUrl: "",
    fetched: false,
    fetching: true,
    fitToWindowVisible: false,
    fystart: 0,
    height: "",
    intervalId: 0,
    pbiToken: {
      expiration: "",
      token: "",
      tokenId: ""
    },
    // token: {
    //   access_token: "",
    //   expires_in: 0,
    //   ext_expires_in: 0,
    //   id_token: "",
    //   not_before: 0,
    //   refresh_token: "",
    //   resource: "",
    //   scope: "",
    //   token_type: ""
    // },
    width: ""
  };
  public report: any;
  constructor(props: IDashboardViewProps) {
    super(props);
    this.getPbiReportAccessToken = this.getPbiReportAccessToken.bind(this);
    this.tokenRefresh = this.tokenRefresh.bind(this);
    this.handleDataSelected = this.handleDataSelected.bind(this);
    this.handleReportLoad = this.handleReportLoad.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.report = null; // to store the loaded report's object to perform operations like print, fullscreen etc...
  }

  public handleDataSelected = (data: any) => {
    // will be called when some chart or data element in your report clicked
  };

  public handleReportLoad = async (report: any) => {
    const { fyStartMonths } = this.props;
    // will be called when report loads
    this.report = report; // get the object from callback and store it. (optional)

    const filterCurrency = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "Currency",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "Currency"
      },
      // tslint:disable-next-line:object-literal-sort-keys
      logicalOperator: "And",
      conditions: [
        {
          operator: "Is",
          value: this.state.currency
        }
      ]
    };

    const filterFYStart = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "LkpPeriod",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "FYear First Month"
      },
      // tslint:disable-next-line:object-literal-sort-keys
      operator: "In",
      values: [
        (fyStartMonths.find(
          month => month.id === this.state.fystart
        ) as IFYStartMonth).name
      ]
    };

    const filterDebtor = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "DimClient",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "DebtorName"
      },
      // tslint:disable-next-line:object-literal-sort-keys
      logicalOperator: "And",
      conditions: [
        {
          operator: "IsNotBlank",
          value: ""
        }
      ]
    };
    const filters = await report.getFilters();
    await filters.push(filterCurrency);
    await filters.push(filterFYStart);
    await filters.push(filterDebtor);
    await report.setFilters(filters);
    report.on("error", (error: any) => {
      // tslint:disable-next-line:no-console
      console.log("Error occurred");
      console.table(error);
    });
    // all events for reports listed below. hook in as needed
    ///////////
    // report.on('loaded', async (e: any) => {
    //   console.log('loaded called'); //<-- this wont ever fire since handleReportLoad fires when 'loaded' fires
    // });
    // report.on("saved", async (e: any) => {
    //   console.log("saved called");
    // });
    // report.on("rendered", async (e: any) => {
    //   console.log("rendered called");
    // });
    // report.on("saveAsTriggered", async (e: any) => {
    //   console.log("saveAsTriggered called");
    // });
    // report.on("error", async (e: any) => {
    //   console.log("error called");
    // });
    // report.on("dataSelected", async (e: any) => {
    //   console.log("dataSelected called");
    // });
    // report.on("buttonClicked", async (e: any) => {
    //   console.log("buttonClicked called");
    // });
    // report.on("pageChanged", async (e: any) => {
    //   console.log("pageChanged called");
    // });
    // report.on("commandTriggered", async (e: any) => {
    //   console.log("commandTriggered called");
    // });
    // report.on("swipeStart", async (e: any) => {
    //   console.log("swipeStart called");
    // });
    // report.on("swipeEnd", async (e: any) => {
    //   console.log("swipeEnd called");
    // });
    // report.on("bookmarkApplied", async (e: any) => {
    //   console.log("bookmarkApplied called");
    // });
    // report.on("dataHyperlinkClicked", async (e: any) => {
    //   console.log("dataHyperlinkClicked called");
    // });
    ///////////
  };

  public handlePageChange = (data: any) => {
    // will be called when pages in your report changes
  };

  public setFullscreen = () => {
    if (this.report) {
      this.setState({
        fitToWindowVisible: true,
        width: "100vw",
        // tslint:disable-next-line:object-literal-sort-keys
        height: "100vh"
      });
      this.report.fullscreen();
    }
  };

  public printReport = () => {
    if (this.report) {
      this.report.print();
    }
  };

  public async getPbiReportAccessToken(accessToken: string) {
    const { dashboard, activeUserRole, apiAccessToken } = this.props;
    const { groupId, reportId } = dashboard;
    const dashboardInfo = (await this.getReportInGroup(
      accessToken
    )) as IPbiData;
    await this.setState({ embedUrl: dashboardInfo.embedUrl });
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${apiAccessToken}`);
    headers.append("AccessToken", accessToken);
    const init: RequestInit = {
      cache: "default",
      headers,
      method: "GET",
      mode: "cors"
    };
    // if the user has no roles, it will return all data, so we set an 'unknown' role if no roles found so that no data will be returned
    const url = `/api/PowerBiService/GetPowerBiReportToken?roles=${
      activeUserRole !== undefined ? activeUserRole.name : "Unknown"
    }&datasetId=${
      dashboardInfo.datasetId
    }&reportId=${reportId}&groupId=${groupId}`;
    const request = new Request(url, init);
    return fetch(request)
      .then(result => result.json())
      .then(data => {
        return data as IPbiTokenData;
      })
      .catch(err => {
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for ${url}`, err);
        return {} as IPbiTokenData;
      });
  }

  public getReportInGroup(accessToken: string) {
    const { dashboard, apiAccessToken } = this.props;
    const { groupId, reportId } = dashboard;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${apiAccessToken}`);
    headers.append("AccessToken", accessToken);
    const init: RequestInit = {
      cache: "default",
      headers,
      method: "GET",
      mode: "cors"
    };
    const url = `/api/PowerBiService/GetReportInGroup?groupId=${groupId}&reportId=${reportId}`;
    const request = new Request(url, init);
    return fetch(request)
      .then(result => result.json())
      .then(data => {
        return data as IPbiData;
      })
      .catch(err =>
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for ${url}`, err)
      );
  }
  public tokenRefresh = async () => {
    const { apiAccessToken, token, onHandleRefreshToken } = this.props;
    await onHandleRefreshToken(apiAccessToken, token.refresh_token);
  };
  public async componentDidUpdate(prevProps: IDashboardViewProps) {
    const { onSetActiveDashboard, dashboard } = this.props;
    if (prevProps.dashboard.id !== dashboard.id) {
      // tslint:disable-next-line:no-console
      console.log("props dashboard change detected, refreshing");
      onSetActiveDashboard(dashboard.id);
      this.tokenRefresh();
    }
  }
  public async componentDidMount() {
    const {
      activeUserRole,
      currencyCodes,
      fyStartMonths,
      dashboard,
      onSetActiveDashboard
    } = this.props;
    onSetActiveDashboard(dashboard.id);
    await this.setState(() => ({ fetching: true, fetched: false }));
    await this.tokenRefresh();
    await this.setState(() => ({ fetching: false, fetched: true }));
    const intervalId = window.setInterval(this.tokenRefresh, 55 * 60 * 1000); // 55 minutes between refreshes of PBI token
    await this.setState(() => ({ intervalId }));

    if (activeUserRole === undefined) {
      this.setState({ currency: "AUD", fystart: 1 });
      throw new Error(
        "At least one role has not been defined, so default CurrencyCode to AUD with a FYStartMonth of January"
      );
    } else {
      const currency = (currencyCodes.find(
        cc => cc.id === activeUserRole.currencyCodeId
      ) as ICurrencyCode).code;
      const fystart = (fyStartMonths.find(
        fysm => fysm.id === activeUserRole.fyStartMonthId
      ) as IFYStartMonth).id;
      this.setState({
        currency,
        fystart
      });
    }
    this.setState({ width: "calc(99vw)", height: "calc(85vh)" });
  }

  public shouldComponentUpdate(
    nextProps: IDashboardViewProps,
    nextState: IDashboardViewState
  ) {
    if (this.props.token.access_token !== nextProps.token.access_token) {
      if (nextProps.token.access_token !== "") {
        this.getPbiReportAccessToken(nextProps.token.access_token).then(
          (pbiToken: IPbiTokenData) => {
            this.setState(() => ({ pbiToken }));
            if (this.report !== null) {
              this.report.setAccessToken(pbiToken.token);
            }
          }
        );
      }
      if (this.props.token.access_token === "") {
        return true;
      } else {
        return false;
      }
    }
    if (this.state.pbiToken.token !== nextState.pbiToken.token) {
      if (this.state.pbiToken.token === "") {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.state.fitToWindowVisible !== nextState.fitToWindowVisible) {
        return false;
      }

      if (this.state.height !== nextState.height) {
        if (this.state.height === "") {
          return true;
        } else {
          return false;
        }
      }
      if (this.state.width !== nextState.width) {
        if (this.state.width === "") {
          return true;
        } else {
          return false;
        }
      }
    }
    if (this.props.dashboard.id !== nextProps.dashboard.id) {
      return true;
    }
    return false;
  }

  public componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  public render() {
    const { dashboard, roleCount } = this.props;
    const { reportId } = dashboard;
    const { fetching, fetched, pbiToken, embedUrl, height, width } = this.state;
    const reportStyle = {
      // style object for report component
      height,
      width
    };
    const extraSettings = {
      filterPanelEnabled: false, // true
      navContentPaneEnabled: true // false
      // any other custom settings
    };
    return (
      <React.Fragment>
        <span style={{ backgroundColor: "white" }}>
          Dashboard: <strong>{dashboard.displayName} </strong>
          {roleCount > 1 && (
            <span>
              {" "}
              using Role: <ActiveRoleBadge />
            </span>
          )}
        </span>

        <Button
          onClick={this.setFullscreen}
          color="info"
          size="sm"
          style={{ margin: 4, float: "right" }}
        >
          Go Full Screen
        </Button>
        <ResetSizeToWindowButton
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => {
            this.setState({
              fitToWindowVisible: false,
              height: "calc(85vh)",
              width: "calc(99vw)"
            });
          }}
          isVisible={this.state.fitToWindowVisible}
        />
        <Button
          onClick={this.printReport}
          color="info"
          size="sm"
          style={{ margin: 4, float: "right" }}
        >
          Print
        </Button>
        {fetching && !fetched && (
          <Jumbotron
            style={{ height: this.state.height, width: this.state.width }}
          >
            <h1>Loading...</h1>
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
          </Jumbotron>
        )}
        {!fetching && fetched && (
          <div id="embedContainer">
            {!pbiToken && <div>Loading...</div>}
            {pbiToken && pbiToken.token && (
              <Report
                key={pbiToken.token} // this allows a re-render when new report loaded
                embedType="report" // "dashboard"
                tokenType="Embed" // "Aad"
                accessToken={pbiToken.token} // access token goes here
                embedUrl={embedUrl} // embedUrl goes here
                embedId={reportId} // report or dashboard id goes here
                extraSettings={extraSettings}
                permissions="View" // "All"
                style={reportStyle}
                onLoad={this.handleReportLoad}
                onSelectData={this.handleDataSelected}
                onPageChange={this.handlePageChange}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
const matchStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let dashboard = {} as IPbiDashboard;
  if (match !== null) {
    const id = (match.params as any).id as string;
    dashboard = state.userDashboards.find(dash => {
      return dash.id === parseInt(id, 10);
    }) as IPbiDashboard;
    // if (dashboard === undefined) {
    //   // tslint:disable-next-line:no-console
    //   console.log("didn't find the dashboard");
    //   return { dashboard: {} as IPbiDashboard };
    // }
  }
  return {
    activeUserRole: state.userRoles.find(
      role => role.id === state.activeRoleId
    ) as IRole,
    roleCount: state.userRoles.length,
    // tslint:disable-next-line:object-literal-sort-keys
    apiAccessToken: state.auth0.accessToken,
    currencyCodes: state.currencyCodes,
    dashboard,
    fyStartMonths: state.fyStartMonths,
    token: state.tokenData
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onClearActiveDashboard: () => {
    dispatch(clearActiveDashboard());
  },
  onSetActiveDashboard: (id: number) => {
    dispatch(setActiveDashboard(id));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleRefreshToken: async (accessToken: string, refreshToken: string) => {
    await dispatch(handleRefreshToken(accessToken, refreshToken));
  }
});

const DashboardViewPage = connect(
  matchStateToProps,
  mapDispatchToProps
)(DashboardView);

export default DashboardViewPage;
