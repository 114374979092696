import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import IGroup from "../../../models/IGroup";
import { IStore } from "../../../models/IStore";
import { handleLoadGroups } from "../../../actions/groups";

export interface ISearchGroupsPageProps {
  accessToken: string;
  onHandleLoadGroups: (accessToken: string) => void;
  groups: IGroup[];
  groupsLoaded: boolean;
}

export interface ISearchGroupsPageState {
  filteredGroups: IGroup[];
  matchedNameExact: boolean;
  name: string;
  noMatchingNames: boolean;
}
class SearchGroupsPage extends React.Component<
  ISearchGroupsPageProps,
  ISearchGroupsPageState
> {
  public isLoaded = false;
  public state = {
    filteredGroups: [] as IGroup[],
    matchedNameExact: false,
    name: "",
    noMatchingNames: false
  };
  constructor(props: ISearchGroupsPageProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public handleInputChange(e: any): void {
    e.preventDefault();
    const { groups } = this.props;

    const searchGroupName = e.target.value.toLowerCase();
    if (!searchGroupName) {
      this.setState({
        filteredGroups: groups
      });
    } else {
      const filteredGroups: IGroup[] = groups.filter((group: IGroup) =>
        group.name.toLowerCase().includes(searchGroupName)
      );
      if (filteredGroups.length === 1) {
        this.setState({ matchedNameExact: true, noMatchingNames: false });
      } else if (filteredGroups.length === 0) {
        this.setState({ noMatchingNames: true, matchedNameExact: false });
      } else {
        this.setState({ matchedNameExact: false, noMatchingNames: false });
      }
      this.setState({
        filteredGroups
      });
    }
  }

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadGroups,
      groups,
      groupsLoaded
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!groupsLoaded) {
        onHandleLoadGroups(accessToken);
      }
      this.setState({ filteredGroups: groups });
    }
  }

  public componentDidUpdate(prevProps: ISearchGroupsPageProps) {
    const { groups } = this.props;
    if (prevProps.groups !== groups) {
      this.setState({
        filteredGroups: groups
      });
    }
  }

  public render() {
    const { groups, groupsLoaded } = this.props;
    const {
      noMatchingNames,
      matchedNameExact,
      name,
      filteredGroups
    } = this.state;
    return (
      <React.Fragment>
        <h1 style={{ backgroundColor: "white" }}>User Group Administration</h1>
        {!groupsLoaded && <DataLoading dataTitle="Groups" />}
        {groupsLoaded && (
          <React.Fragment>
            <FormGroup>
              <Card>
                <CardTitle>
                  <Label for="name">Select Group To Edit</Label>
                </CardTitle>
                <CardBody tag="span">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Filter Group"
                    onChange={this.handleInputChange}
                    valid={matchedNameExact}
                    invalid={noMatchingNames}
                  />
                  <FormFeedback valid={true}>
                    {matchedNameExact && (
                      <span>
                        Group found! Select to edit
                        {name}
                      </span>
                    )}
                  </FormFeedback>
                  <FormFeedback invalid="true">
                    {noMatchingNames && (
                      <span>No Groups match name entered </span>
                    )}
                  </FormFeedback>
                </CardBody>
              </Card>
            </FormGroup>
            <Card>
              <CardBody>
                {groups.length > 0 &&
                  filteredGroups.map((group: IGroup) => (
                    <div key={group.id}>
                      <Link to={paths.editGroup.concat(group.id.toString())}>
                        <span>{group.name}</span>
                      </Link>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    groups: state.groups,
    groupsLoaded: state.loadingGroups
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadGroups: (accessToken: string) => {
    dispatch(handleLoadGroups(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchGroupsPage);
