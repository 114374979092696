import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { handleLoadReports } from "../../../actions/reports";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import ISqlReport from "../../../models/ISqlReport";
import { IStore } from "../../../models/IStore";

export interface ISearchReportsPageProps {
  accessToken: string;
  onHandleLoadReports: (accessToken: string) => void;
  reports: ISqlReport[];
  reportsLoaded: boolean;
}

export interface ISearchReportsPageState {
  filteredReports: ISqlReport[];
  matchedNameExact: boolean;
  name: string;
  noMatchingNames: boolean;
}
class SearchReportsPage extends React.Component<
  ISearchReportsPageProps,
  ISearchReportsPageState
> {
  public isLoaded = false;
  public state = {
    filteredReports: [] as ISqlReport[],
    matchedNameExact: false,
    name: "",
    noMatchingNames: false
  };
  constructor(props: ISearchReportsPageProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public handleInputChange(e: any): void {
    e.preventDefault();
    const { reports } = this.props;

    const searchReportDisplayName = e.target.value.toLowerCase();
    if (!searchReportDisplayName) {
      this.setState({
        filteredReports: reports
      });
    } else {
      const filteredReports: ISqlReport[] = reports.filter(
        (report: ISqlReport) =>
          report.displayName.toLowerCase().includes(searchReportDisplayName)
      );
      if (filteredReports.length === 1) {
        this.setState({ matchedNameExact: true, noMatchingNames: false });
      } else if (filteredReports.length === 0) {
        this.setState({ noMatchingNames: true, matchedNameExact: false });
      } else {
        this.setState({ matchedNameExact: false, noMatchingNames: false });
      }
      this.setState({
        filteredReports
      });
    }
  }

  public componentDidMount() {
    const { accessToken, onHandleLoadReports, reports, reportsLoaded } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!reportsLoaded) {
        onHandleLoadReports(accessToken);
      }
      this.setState({
        filteredReports: reports
      });
    }
  }
  public componentDidUpdate(prevProps: ISearchReportsPageProps) {
    const { reports } = this.props;
    if (prevProps.reports !== reports) {
      this.setState({
        filteredReports: reports
      });
    }
  }
  public render() {
    const { reports, reportsLoaded } = this.props;
    const {
      noMatchingNames,
      matchedNameExact,
      name,
      filteredReports
    } = this.state;
    return (
      <React.Fragment>
        <h1 style={{ backgroundColor: "white" }}>Report Administration</h1>
        {!reportsLoaded && <DataLoading dataTitle="Reports" />}
        {reportsLoaded && (
          <React.Fragment>
            <FormGroup>
              <Card>
                <CardTitle>
                  <Label for="name">Select Report To Edit</Label>
                </CardTitle>
                <CardBody tag="span">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Filter Report"
                    onChange={this.handleInputChange}
                    valid={matchedNameExact}
                    invalid={noMatchingNames}
                  />
                  <FormFeedback valid={true}>
                    {matchedNameExact && (
                      <span>
                        report found! Select to edit
                        {name}
                      </span>
                    )}
                  </FormFeedback>
                  <FormFeedback invalid="true">
                    {noMatchingNames && (
                      <span>No reports match name entered </span>
                    )}
                  </FormFeedback>
                </CardBody>
              </Card>
            </FormGroup>
            <Card>
              <CardBody>
                {reports.length > 0 &&
                  filteredReports.map((report: ISqlReport) => (
                    <div key={report.id}>
                      <Link to={paths.editReport.concat(report.id.toString())}>
                        <span>{report.displayName}</span>
                      </Link>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    reports: state.reports,
    reportsLoaded: state.loadingReports
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadReports: (accessToken: string) => {
    dispatch(handleLoadReports(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchReportsPage);
