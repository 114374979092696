import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading
} from "reactstrap";
import IPbiDashboard from "../../models/IPbiDashboard";
import { IStore } from "../../models/IStore";
import paths from "../../constants";

export interface IUserDashboardsCardProps {
  dashboards: IPbiDashboard[];
}

const UserDashboardsCard: React.SFC<IUserDashboardsCardProps> = (
  props: IUserDashboardsCardProps
) => {
  const { dashboards } = props;
  return (
    <React.Fragment>
      <Card>
        <ListGroup>
          <ListGroupItemHeading style={{ backgroundColor: "white" }}>
            Your Dashboards
          </ListGroupItemHeading>
          {dashboards.map(dashboard => (
            <ListGroupItem action={true} variant="Light" key={dashboard.id}>
              <Link to={paths.pbiDashboards.concat(dashboard.id.toString())}>
                {dashboard.displayName}
              </Link>
            </ListGroupItem>
          ))}
          {(dashboards===undefined || (dashboards!==undefined && dashboards.length===0) )&&(<span>You have no available dashboards</span>)}
        </ListGroup>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    dashboards: state.userDashboards
  };
};

export default connect(mapStateToProps)(UserDashboardsCard);
