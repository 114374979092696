import { handleLoadFinancialYears } from "actions/financialYears";
import IFinancialYear from "models/IFinancialYear";
import IPredicate from "models/IPredicate";
import Report from "powerbi-report-component";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Spinner } from "reactstrap";
import {
  clearActiveDashboard,
  setActiveDashboard
} from "../../actions/activeDashboard";
import { handleRefreshToken } from "../../actions/tokenData";
import { settings } from "../../constants";
import ICurrencyCode from "../../models/ICurrencyCode";
import IFYStartMonth from "../../models/IFYStartMonth";
import IPbiDashboard from "../../models/IPbiDashboard";
import { IPbiData } from "../../models/IPbiData";
import { IPbiTokenData } from "../../models/IPbiTokenData";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";
import { ITokenData } from "../../models/ITokenData";
import Jumbotron from "../atoms/Jumbotron";
import ActiveRoleBadge from "../molecules/ActiveRoleBadge";
import ResetSizeToWindowButton from "../molecules/ResetSizeToWindowButton";

export interface IDashboardViewProps {
  apiAccessToken: string;
  currencyCodes: ICurrencyCode[];
  dashboard: IPbiDashboard;
  fyStartMonths: IFYStartMonth[];
  token: ITokenData;
  activeUserRole: IRole;
  defaultClientName: string;
  defaultPredicateDisplayName: string;
  onSetActiveDashboard: (id: number) => void;
  onClearActiveDashboard: () => void;
  onHandleRefreshToken: (accessToken: string, refreshToken: string) => void;
  roleCount: number;
  fys: IFinancialYear[];
  fysLoaded: boolean;
  onHandleLoadFinancialYears: (accessToken: string) => void;
}

export interface IDashboardViewState {
  currency: string;
  embedUrl: string;
  fetched: boolean;
  fetching: boolean;
  fitToWindowVisible: boolean;
  fystart: number;
  height: string;
  intervalId: number;
  valuesLoaded: boolean;
  pbiToken: IPbiTokenData;
  width: string;
}

export class DashboardView extends React.Component<
  IDashboardViewProps,
  IDashboardViewState
> {
  public state = {
    currency: "",
    embedUrl: "",
    fetched: false,
    fetching: true,
    fitToWindowVisible: false,
    fystart: 0,
    height: "",
    intervalId: 0,
    valuesLoaded: false,
    pbiToken: {
      expiration: "",
      token: "",
      tokenId: ""
    },
    width: ""
  };
  public report: any;
  constructor(props: IDashboardViewProps) {
    super(props);
    this.getCurrentFinancialYear = this.getCurrentFinancialYear.bind(this);
    this.configureKpiPageFilters = this.configureKpiPageFilters.bind(this);
    this.configureSimulationPageFilters = this.configureSimulationPageFilters.bind(
      this
    );
    this.handleDataSelected = this.handleDataSelected.bind(this);
    this.handleReportLoad = this.handleReportLoad.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.setFullscreen = this.setFullscreen.bind(this);
    this.setRefresh = this.setRefresh.bind(this);
    this.printReport = this.printReport.bind(this);
    this.getPbiReportAccessToken = this.getPbiReportAccessToken.bind(this);
    this.getReportInGroup = this.getReportInGroup.bind(this);
    this.tokenRefresh = this.tokenRefresh.bind(this);

    this.report = null; // to store the loaded report's object to perform operations like print, fullscreen etc...
  }

  public getCurrentFinancialYear = (fyStartMonthId: number) => {
    const { fys } = this.props;
    const fyStart = new Date(new Date().getFullYear(), fyStartMonthId - 1, 1);
    const fyEnd = new Date(
      new Date().getFullYear() + 1,
      fyStartMonthId - 1,
      0,
      23,
      59,
      59,
      999
    );
    const today = new Date().getDate();
    const lastFinancialMonth = new Date(Date.now() - today * 864e5); //864e5 == 86500000 = 24(h)*60(m)*60(s)*1000(ms)

    // console.table("Financial Year Start ID: ", fyStartMonthId);
    // console.table("Financial Year Start Date", fyStart);
    // console.table("Financial Year End Date", fyEnd);
    // console.table("Today's Date: ", today);
    // console.table("Last Financial Month's last day: ", lastFinancialMonth);
    // console.table("Financial Year starts after the last financial month date", fyStart<lastFinancialMonth);
    // console.table("Financial Year ends after the last financial month date? ", fyEnd>lastFinancialMonth);

    if (fyStart < lastFinancialMonth) {
      //New financial Year has started already for this calendar year
      return (fys.find(
        fy => fy.endYear === fyEnd.getFullYear()
      ) as IFinancialYear).displayName;
    } else {
      // New financial year has not yet started for this calendar year
      return (fys.find(
        fy => fy.endYear === fyEnd.getFullYear() - 1
      ) as IFinancialYear).displayName;
    }
  };

  public configureKpiPageFilters = async (page: any) => {
    const { defaultClientName, defaultPredicateDisplayName } = this.props;
    const filters = await page.getFilters();
    const filterClient = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "KpiDimValue",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "DimValueName"
      },
      // tslint:disable-next-line:object-literal-sort-keys
      logicalOperator: "And",
      conditions: [
        {
          operator: "Is",
          value: defaultClientName.substring(0, defaultClientName.length - 6) // To eliminate appended currency code e.g. (AUD)
        }
      ]
    };
    const filterPredicate = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "KpiDim",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "DimName"
      },
      // tslint:disable-next-line:object-literal-sort-keys
      logicalOperator: "And",
      conditions: [
        {
          operator: "Is",
          value: defaultPredicateDisplayName
        }
      ]
    };
    filters.push(filterClient);
    filters.push(filterPredicate);
    page.setFilters(filters);
  };
  public configureSimulationPageFilters = async (page: any) => {
    const { defaultClientName, defaultPredicateDisplayName } = this.props;
    const filters = await page.getFilters();
    // const filterClient = {
    //   $schema: "http://powerbi.com/product/schema#basic",
    //   target: {
    //     table: "KpiDimValue",
    //     column: "DimValueName"
    //   },
    //   logicalOperator: "And",
    //   conditions: [
    //     {
    //       operator: "Is",
    //       value: defaultClientName.substring(0, defaultClientName.length - 6) // To eliminate appended currency code
    //     }
    //   ]
    // };
    const filterClient = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "KpiDimValue",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "DimValueName"
      },
      operator: "In",
      values: [defaultClientName.substring(0, defaultClientName.length - 6)]
    };
    // const filterPredicate = {
    //   $schema: "http://powerbi.com/product/schema#basic",
    //   target: {
    //     table: "KpiDim",
    //     column: "DimName"
    //   },
    //   logicalOperator: "And",
    //   conditions: [
    //     {
    //       operator: "Is",
    //       value: defaultPredicateDisplayName
    //     }
    //   ]
    // };
    const filterPredicate = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "KpiDim",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "DimName"
      },
      operator: "In",
      values: [defaultPredicateDisplayName]
    };
    filters.push(filterClient);
    filters.push(filterPredicate);
    page.setFilters(filters);
  };

  public handleDataSelected = (data: any) => {
    // will be called when some chart or data element in your report clicked
  };

  public handleReportLoad = async (report: any) => {
    // will be called when report loads
    this.report = report; // get the object from callback and store it. (optional)
    // console.log("handleReportLoad loaded");
    const { fyStartMonths } = this.props;
    const pages = await report.getPages();

    // find the KPI Page and set the filters for it
    const kpiPage = pages.find(
      (page: any) => page.displayName === settings.kpiDashboardPageName
    );
    await this.configureKpiPageFilters(kpiPage);

 
      const simulationPage = pages.find(
      (page: any) => page.displayName === settings.simulationDashboardPageName
      );

      if (simulationPage != undefined) { 
          await this.configureSimulationPageFilters(simulationPage);
      }
    //return focus to the Summary Page
 
    const filterCurrency = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Currency",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "Currency"
      },
      operator: "In",
      values: [this.state.currency]
    };

    const filterFinYearName = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "LkpClientFinYear",
        // tslint:disable-next-line:object-literal-sort-keys
        column: "FinYearName"
      },
      operator: "In",
      values: [this.getCurrentFinancialYear(this.state.fystart)]
    };
    const filterFinYearFirstMonth = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "LkpClientFinYear",
        column: "FinYearFirstMonth"
      },
      operator: "In",
      values: [
        (fyStartMonths.find(
          month => month.id === this.state.fystart
        ) as IFYStartMonth).name
      ]
    };
    const filters = await report.getFilters();
    await filters.push(filterCurrency);
    await filters.push(filterFinYearName);
    await filters.push(filterFinYearFirstMonth);
    // console.table(filters);
    await report.setFilters(filters);
    pages[0].setActive();
    report.on("error", (error: any) => {
      // tslint:disable-next-line:no-console
      console.log("Error occurred");
      console.table(error);
    });

    // all events for reports listed below. hook in as needed
    ///////////
    // report.on('loaded', async (e: any) => {
    //   console.log('loaded called'); //<-- this wont ever fire since handleReportLoad fires when 'loaded' fires
    // });
    // report.on("saved", async (e: any) => {
    //   console.log("saved called");
    // });
    // report.on("rendered", async (e: any) => {
    //   console.log("rendered called");
    // });
    // report.on("saveAsTriggered", async (e: any) => {
    //   console.log("saveAsTriggered called");
    // });
    // report.on("error", async (e: any) => {
    //   console.log("error called");
    // });
    // report.on("dataSelected", async (e: any) => {
    //   console.log("dataSelected called");
    // });
    // report.on("buttonClicked", async (e: any) => {
    //   console.log("buttonClicked called");
    // });
    // report.on("pageChanged", async (e: any) => {
    //   console.log("pageChanged called");
    // });
    // report.on("commandTriggered", async (e: any) => {
    //   console.log("commandTriggered called");
    // });
    // report.on("swipeStart", async (e: any) => {
    //   console.log("swipeStart called");
    // });
    // report.on("swipeEnd", async (e: any) => {
    //   console.log("swipeEnd called");
    // });
    // report.on("bookmarkApplied", async (e: any) => {
    //   console.log("bookmarkApplied called");
    // });
    // report.on("dataHyperlinkClicked", async (e: any) => {
    //   console.log("dataHyperlinkClicked called");
    // });
    ///////////
  };

  public handlePageChange = (data: any) => {
    // will be called when pages in your report changes
  };

  public setFullscreen = () => {
    if (this.report) {
      this.setState({
        fitToWindowVisible: true,
        width: "100vw",
        height: "100vh"
      });
      this.report.fullscreen();
    }
  };

  public setRefresh = () => {
    if (this.report) {
      this.report.refresh().then((result: any) => {
        console.info("Report values refreshed");
      });
    }
  };

  public printReport = () => {
    if (this.report) {
      this.report.print();
    }
  };

  public async getPbiReportAccessToken(accessToken: string) {
    const { dashboard, activeUserRole, apiAccessToken } = this.props;
    const { groupId, reportId } = dashboard;
    const dashboardInfo = (await this.getReportInGroup(
      accessToken
    )) as IPbiData;
    this.setState({ embedUrl: dashboardInfo.embedUrl });
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${apiAccessToken}`);
    headers.append("AccessToken", accessToken);
    const init: RequestInit = {
      cache: "default",
      headers,
      method: "GET",
      mode: "cors"
    };
    // if the user has no roles, it will return all data, so we set an 'unknown' role if no roles found so that no data will be returned
    const url = `/api/PowerBiService/GetPowerBiReportToken?roles=${
      activeUserRole !== undefined ? activeUserRole.name : "Unknown"
    }&datasetId=${
      dashboardInfo.datasetId
    }&reportId=${reportId}&groupId=${groupId}`;
    const request = new Request(url, init);
    return fetch(request)
      .then(result => result.json())
      .then(data => {
        return data as IPbiTokenData;
      })
      .catch(err => {
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for ${url}`, err);
        return {} as IPbiTokenData;
      });
  }

  public getReportInGroup(accessToken: string) {
    const { dashboard, apiAccessToken } = this.props;
    const { groupId, reportId } = dashboard;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${apiAccessToken}`);
    headers.append("AccessToken", accessToken);
    const init: RequestInit = {
      cache: "default",
      headers,
      method: "GET",
      mode: "cors"
    };
    const url = `/api/PowerBiService/GetReportInGroup?groupId=${groupId}&reportId=${reportId}`;
    const request = new Request(url, init);
    return fetch(request)
      .then(result => result.json())
      .then(data => {
        return data as IPbiData;
      })
      .catch(err =>
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for ${url}`, err)
      );
  }
  public tokenRefresh = async () => {
    const { apiAccessToken, token, onHandleRefreshToken } = this.props;
    onHandleRefreshToken(apiAccessToken, token.refresh_token);
  };
  public async componentDidUpdate(prevProps: IDashboardViewProps) {
    const { onSetActiveDashboard, dashboard } = this.props;
    if (prevProps.dashboard.id !== dashboard.id) {
      // tslint:disable-next-line:no-console
      console.log("props dashboard change detected, refreshing");
      onSetActiveDashboard(dashboard.id);
      await this.tokenRefresh();
    }
  }
  public async componentDidMount() {
    const {
      activeUserRole,
      currencyCodes,
      fyStartMonths,
      dashboard,
      onSetActiveDashboard,
      fysLoaded,
      onHandleLoadFinancialYears,
      apiAccessToken
    } = this.props;
    if (!fysLoaded) {
      onHandleLoadFinancialYears(apiAccessToken);
    }
    onSetActiveDashboard(dashboard.id);
    this.setState(() => ({ fetching: true, fetched: false }));
    await this.tokenRefresh();
    this.setState(() => ({ fetching: false, fetched: true }));
    const intervalId = window.setInterval(this.tokenRefresh, 55 * 60 * 1000); // 55 minutes between refreshes of PBI token
    this.setState(() => ({ intervalId }));

    if (activeUserRole === undefined) {
      this.setState({ currency: "AUD", fystart: 1 });
      throw new Error(
        "At least one role has not been defined, so default CurrencyCode to AUD with a FYStartMonth of January"
      );
    } else {
      const currency = (currencyCodes.find(
        cc => cc.id === activeUserRole.currencyCodeId
      ) as ICurrencyCode).code;
      const fystart = (fyStartMonths.find(
        fysm => fysm.id === activeUserRole.fyStartMonthId
      ) as IFYStartMonth).id;
      this.setState({
        currency,
        fystart
      });
    }
    this.setState({ width: "calc(99vw)", height: "calc(85vh)" });
  }

  public shouldComponentUpdate(
    nextProps: IDashboardViewProps,
    nextState: IDashboardViewState
  ) {
    if (this.props.token.access_token !== nextProps.token.access_token) {
      if (nextProps.token.access_token !== "") {
        this.getPbiReportAccessToken(nextProps.token.access_token).then(
          (pbiToken: IPbiTokenData) => {
            this.setState(() => ({ pbiToken }));
            if (this.report !== null) {
              this.report.setAccessToken(pbiToken.token);
            }
          }
        );
      }
      if (this.props.token.access_token === "") {
        return true;
      } else {
        return false;
      }
    }
    if (this.state.pbiToken.token !== nextState.pbiToken.token) {
      if (this.state.pbiToken.token === "") {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.state.fitToWindowVisible !== nextState.fitToWindowVisible) {
        return false;
      }

      if (this.state.height !== nextState.height) {
        if (this.state.height === "") {
          return true;
        } else {
          return false;
        }
      }
      if (this.state.width !== nextState.width) {
        if (this.state.width === "") {
          return true;
        } else {
          return false;
        }
      }
    }
    if (this.props.dashboard.id !== nextProps.dashboard.id) {
      return true;
    }
    return false;
  }

  public componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  public render() {
    const { dashboard, roleCount } = this.props;
    const { reportId } = dashboard;
    const { fetching, fetched, pbiToken, embedUrl, height, width } = this.state;
    const reportStyle = {
      // style object for report component
      height,
      width
    };
    const extraSettings = {
      filterPanelEnabled: false, // true
      navContentPaneEnabled: true // false
      // any other custom settings
    };
    return (
      <React.Fragment>
        <span style={{ backgroundColor: "white" }}>
          Dashboard: <strong>{dashboard.displayName}</strong>
          {roleCount > 1 && (
            <span>
              {" "}
              using Role: <ActiveRoleBadge />
            </span>
          )}
        </span>
        <Button
          onClick={this.setFullscreen}
          color="info"
          size="sm"
          style={{ margin: 4, float: "right" }}
        >
          Go Full Screen
        </Button>
        <ResetSizeToWindowButton
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => {
            this.setState({
              fitToWindowVisible: false,
              height: "calc(85vh)",
              width: "calc(99vw)"
            });
          }}
          isVisible={this.state.fitToWindowVisible}
        />
        <Button
          onClick={this.printReport}
          color="info"
          size="sm"
          style={{ margin: 4, float: "right" }}
        >
          Print
        </Button>
        <Button
          onClick={this.setRefresh}
          color="info"
          size="sm"
          style={{ margin: 4, float: "right" }}
        >
          &#8634;
        </Button>

        {fetching && !fetched && (
          <Jumbotron
            style={{ height: this.state.height, width: this.state.width }}
          >
            <h1>Loading...</h1>
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
            <Spinner color="info" type="grow" />
          </Jumbotron>
        )}
        {!fetching && fetched && (
          <div id="embedContainer">
            {!pbiToken && <div>Loading...</div>}
            {pbiToken && pbiToken.token && (
              <Report
                key={pbiToken.token} // this allows a re-render when new report loaded
                embedType="report" // "dashboard"
                tokenType="Embed" // "Aad"
                accessToken={pbiToken.token} // access token goes here
                embedUrl={embedUrl} // embedUrl goes here
                embedId={reportId} // report or dashboard id goes here
                extraSettings={extraSettings}
                permissions="View" // "All"
                style={reportStyle}
                onLoad={this.handleReportLoad}
                onSelectData={this.handleDataSelected}
                onPageChange={this.handlePageChange}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
const matchStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let dashboard = {} as IPbiDashboard;
  if (match !== null) {
    const id = (match.params as any).id as string;
    dashboard = state.userDashboards.find(dash => {
      return dash.id === parseInt(id, 10);
    }) as IPbiDashboard;
  }
  const defaultPredicate = state.predicates.find(
    pred => pred.id === state.activeDashboardDefaultPredicateId
  ) as IPredicate;
  return {
    activeUserRole: state.userRoles.find(
      role => role.id === state.activeRoleId
    ) as IRole,
    roleCount: state.userRoles.length,
    // tslint:disable-next-line:object-literal-sort-keys
    apiAccessToken: state.auth0.accessToken,
    currencyCodes: state.currencyCodes,
    dashboard,
    defaultClientName: state.activeDashboardDefaultClient.displayName,
    defaultPredicateDisplayName: defaultPredicate
      ? defaultPredicate.displayName
      : "",
    fyStartMonths: state.fyStartMonths,
    token: state.tokenData,
    fys: state.financialYears,
    fysLoaded: state.loadingFinancialYears
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onClearActiveDashboard: () => {
    dispatch(clearActiveDashboard());
  },
  onSetActiveDashboard: (id: number) => {
    dispatch(setActiveDashboard(id));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleRefreshToken: async (accessToken: string, refreshToken: string) => {
    await dispatch(handleRefreshToken(accessToken, refreshToken));
  },
  onHandleLoadFinancialYears: (accessToken: string) => {
    dispatch(handleLoadFinancialYears(accessToken));
  }
});

const DashboardViewPageV2 = connect(
  matchStateToProps,
  mapDispatchToProps
)(DashboardView);

export default DashboardViewPageV2;
