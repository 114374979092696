import * as React from "react";
import { Card, CardFooter, CardTitle, Spinner } from "reactstrap";

export interface IDataLoadingProps{
  dataTitle?: string;
}

const DataLoading:React.SFC<IDataLoadingProps> = (props:IDataLoadingProps) => {
  const {dataTitle} = props;
  return (
    <Card>
      <CardTitle>{dataTitle===undefined?"":dataTitle} {" "} Data is loading...please wait...</CardTitle>
      <CardFooter>
        <Spinner color="info" type="grow" />
        <Spinner color="info" type="grow" />
        <Spinner color="info" type="grow" />
        <Spinner color="info" type="grow" />
        <Spinner color="info" type="grow" />
      </CardFooter>
    </Card>
  );
};

export default DataLoading;
