import * as React from "react";
export interface INavListLeftProps {
  children: React.ReactNode;
}
const NavListLeft: React.SFC<INavListLeftProps> = (
  props: INavListLeftProps
) => {
  const { children } = props;
  return (
    <div className="navbar-nav-scroll">
      <ul className="navbar-nav bd-navbar-nav flex-row">{children}</ul>
    </div>
  );
};

export default NavListLeft;
