export const paths = {
  addDashboard: "/admin/dashboards/add/",
  addGroup: "/admin/groups/add/",
  addKpi: "/admin/kpis/add/",
  addPredicate: "/admin/predicates/add/",
  addReport: "/admin/reports/add/",
  addRole: "/admin/roles/add/",
  addRoleWizard: "/admin/roles/addWizard/",
  addUser: "/admin/users/add/",
  admin: "/admin/",
  callback: "/callback/",
  editDashboard: "/admin/dashboards/edit/",
  editGroup: "/admin/groups/edit/",
  editKpi: "/admin/kpis/edit/",
  editPredicate: "/admin/predicates/edit/",
  editReport: "/admin/reports/edit/",
  editRole: "/admin/roles/edit/",
  editUser: "/admin/users/edit/",
    home: "/home/",

  pbiDashboards: "/dashboards/",
  pbiPaginatedReports: "/paginatedReports/",


  profile: "/profile/",
  quickAddUser: "/admin/users/quick-add",
  root: "/",
  searchDashboards: "/admin/dashboards/search/",
  searchGroups: "/admin/groups/search/",
  searchKpis: "/admin/kpis/search/",
  searchPredicates: "/admin/predicates/search",
  searchReports: "/admin/reports/search",
  searchRoles: "/admin/roles/search/",
    searchUsers: "/admin/users/search/",

  ssosaml: "/ssosaml/",
  ssosamldevau: "/ssosamldevau",
  ssosamlqaau: "/ssosamlqaau",  
  ssosamlqana: "/ssosamlqana",  
  ssosamlprodna: "/ssosamlprodna",
  ssosamlstagingau: "/ssosamlstagingau",
  ssosamlprodas: "/ssosamlprodas",

  ssosamlprodau: "/ssosamlprodau",
  ssosamlqa: "/ssosamlqa",

  //For new CTM portal AU:
  ssosamlprodprtlau: "/ssosamlprtlau",
  ssosamlqaprtlau: "/ssosamlqaprtlau",
  ssosamldevprtlau: "/ssosamldevprtlau",

  sqlReports: "/reports/",
  // tslint:disable-next-line:object-literal-sort-keys
  kpitargets: "/kpi/",
  addKpiTargets: "/admin/kpis/add/",
  addFinancialYear: "/admin/kpis/financialYears/add/"
};

export const settings = {
  allowAlternateSqlReportingServerUrl: false,
  allowAlternateSqlReportingViewerUrl: false,
  allowDisableDashboards: false,
  allowDisableGroups: false,
  allowDisableReports: false,
  allowDisableRoles: false,
  allowDisableUsers: false,
  // tslint:disable-next-line:object-literal-sort-keys
  allowDisableFinancialYears: false,
  allowDisablePredicates: false,
  allowDisableKpis: false,
  maxDashboardViewerVersion: 2,
  kpiDashboardPageName: "KPIs",
    simulationDashboardPageName: "Simulation",
    allowDisablePaginatedReports: false
};

export const zoomModes = [
  "Whole Page",
  "Page Width",
  "Percent",
  "500%",
  "200%",
  "150%",
  "100%",
  "75%",
  "50%",
  "25%",
  "10%"
];

export const editModes = {
  add: 0,
  view: 1,
  // tslint:disable-next-line:object-literal-sort-keys
  edit: 2
};
export const roleWizardStages = {
  addInitialRoleDetails: 0,
  addPredicateParameters: 1,
  assignDashboards: 2,
  assignReports: 3,
  assignUsers: 4,
  reviewAndComplete: 5
};

export const userWizardStages = {
  addInitialUserDetails: 0,
  assignRoles: 1,
  // tslint:disable-next-line:object-literal-sort-keys
  assignGroups: 2,
  reviewAndComplete: 3
};

export const adminGroupName = "Company Administrator";
export const kpiAdminGroupName = "Client KPI Administrator";
export const superAdminGroupName = "Super Administrator";

export default paths;
