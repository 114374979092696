import { fetchSecureData } from "../utils";

export const removeUserGroup = async (accessToken: string, userId: number, groupId: number) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
      `/api/UserProfileGroup/Remove?userId=${userId}&groupId=${groupId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/UserProfileGroup/Remove?userId=${userId}&groupId=${groupId}`, err);
  }
  return 404;
};

export default removeUserGroup;
