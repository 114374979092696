import { AnyAction } from "redux";
import {
  ADD_DASHBOARD,
  LOAD_DASHBOARDS,
  REMOVE_DASHBOARD,
  UPDATE_DASHBOARD
} from "../actions/dashboards";
import IPbiDashboard from "../models/IPbiDashboard";

export const dashboards = (
  state = [] as IPbiDashboard[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_DASHBOARD:
      const newState: IPbiDashboard[] = state.slice();
      newState.push(action.dashboard as IPbiDashboard);
      return newState;
    case REMOVE_DASHBOARD:
      return Object.assign(
        [],
        state.filter(
          (dshbd: IPbiDashboard) => dshbd.id !== (action.id as number)
        )
      ) as IPbiDashboard[];
    case UPDATE_DASHBOARD:
      return Object.assign(
        [],
        state.map((dshbd: IPbiDashboard) => {
          if (dshbd.id === (action.dashboard as IPbiDashboard).id) {
            return action.dashboard as IPbiDashboard;
          }
          return dshbd;
        })
      ) as IPbiDashboard[];
    case LOAD_DASHBOARDS:
      return action.dashboards as IPbiDashboard[];
    default:
      return state;
  }
};

export default dashboards;
