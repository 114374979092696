import * as React from "react";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";

export interface IActiveRoleBadgeProps {
  activeRoleId: number;
  availableRoles: IRole[];
}

const ActiveRoleBadge: React.SFC<IActiveRoleBadgeProps> = (
  props: IActiveRoleBadgeProps
) => {
  const { activeRoleId, availableRoles } = props;
  return (
    <Badge>
      {
        (availableRoles.find(role => role.id === activeRoleId) as IRole)
          .displayName
      }
    </Badge>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    activeRoleId: state.activeRoleId,
    availableRoles: state.userRoles
  };
};
export default connect(mapStateToProps)(ActiveRoleBadge);
