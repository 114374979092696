import { combineReducers } from "redux";
import activeDashboard from "./activeDashboard";
import activeDashboardDefaultClient from "./activeDashboardDefaultClient";
import activeDashboardDefaultPredicateId from "./activeDashboardDefaultPredicate";

import activePaginatedReport from "./activePaginatedReport";

import activeKpi from "./activeKpi";
import activeKpiClient from "./activeKpiClient";
import activeKpiEntries from "./activeKpiEntries";
import activeKpiFinancialYear from "./activeKpiFinancialYear";
import activeKpiPredicate from "./activeKpiPredicate";

import activeReport from "./activeReport";
import activeRole from "./activeRole";
import activeRoleClients from "./activeRoleClients";
import activeRoleKpiGroupingPredicates from "./activeRoleKpiGroupingPredicates";
import activeUserGroup from "./activeUserGroup";
import auth0 from "./auth0";
import currencyCodes from "./currencyCodes";

import dashboards from "./dashboards";
import paginatedReports from "./paginatedReports";

import editRoleDashboardIds from "./editRoleDashboardIds";
import editRoleDetails from "./editRoleDetails";
import editRolePredicates from "./editRolePredicates";
import editRoleReportIds from "./editRoleReportIds";
import editRoleUserIds from "./editRoleUserIds";
import editUserDetails from "./editUserDetails";
import editUserGroupIds from "./editUserGroupIds";
import editUserRoleIds from "./editUserRoleIds";
import financialYears from "./financialYears";
import fyStartMonths from "./fyStartMonths";
import groups from "./groups";
import kpis from "./kpis";
import loading from "./loading";
import loadingCurrencyCodes from "./loadingCurrencyCodes";


import loadingDashboards from "./loadingDashboards";
import loadingEditRoleDashboards from "./loadingEditRoleDashboards";


import loadingPaginatedReports from "./loadingPaginatedReports";


import loadingEditRolePredicates from "./loadingEditRolePredicates";
import loadingEditRoleReports from "./loadingEditRoleReports";
import loadingEditRoleUsers from "./loadingEditRoleUsers";
import loadingEditUserDetails from "./loadingEditUserDetails";
import loadingEditUserGroups from "./loadingEditUserGroups";
import loadingEditUserRoles from "./loadingEditUserRoles";
import loadingFinancialYears from "./loadingFinancialYears";
import loadingFyStartMonths from "./loadingFyStartMonths";
import loadingGroups from "./loadingGroups";
import loadingKpiEntries from "./loadingKpiEntries";
import loadingKpis from "./loadingKpis";
import loadingPredicates from "./loadingPredicates";
import loadingReports from "./loadingReports";
import loadingRoleClients from "./loadingRoleClients";
import loadingRoleKpiGroupingPredicates from "./loadingRoleKpiGroupingPredicates";
import loadingRoles from "./loadingRoles";

import loadingUserDashboards from "./loadingUserDashboards";
import loadingUserPaginatedReports from "./loadingUserPaginatedReports";


import loadingUserGroups from "./loadingUserGroups";
import loadingUserReports from "./loadingUserReports";
import loadingUserRoles from "./loadingUserRoles";
import loadingUsers from "./loadingUsers";
import predicates from "./predicates";
import profiles from "./profiles";
import reports from "./reports";
import rolePredicates from "./rolePredicates";
import roles from "./roles";
import tokenData from "./tokenData";

import userDashboards from "./userDashboards";
import userPaginatedReports from "./userPaginatedReports";

import userGroups from "./userGroups";
import userProfile from "./userProfile";
import userReports from "./userReports";
import userRoles from "./userRoles";

export const allReducers = combineReducers({
  auth0,
  currencyCodes,
  fyStartMonths,
  predicates,
  userProfile,
  // tslint:disable-next-line:object-literal-sort-keys
    userDashboards,
    userPaginatedReports,
  userRoles,
  userReports,
  userGroups,
    dashboards,
    paginatedReports,

  groups,
  profiles,
  reports,
  roles,
  rolePredicates,
  editRoleDetails,
  editRoleDashboardIds,
  editRolePredicates,
  editRoleReportIds,
  editRoleUserIds,
  editUserDetails,
  editUserGroupIds,
    editUserRoleIds,

    activeDashboardId: activeDashboard,
    activePaginatedReportId: activePaginatedReport,

  activeReportId: activeReport,
  activeRoleId: activeRole,
  activeUserGroupId: activeUserGroup,
  tokenData,
  loading,
  loadingEditUserDetails,
  loadingEditUserGroups,
  loadingEditUserRoles,
  loadingUsers,
  loadingGroups,
  loadingRoles,
  loadingCurrencyCodes,
  loadingFyStartMonths,
  loadingPredicates,
    loadingUserRoles,

    loadingUserDashboards,
    loadingUserPaginatedReports,

  loadingUserReports,
  loadingUserGroups,
    loadingReports,

    loadingDashboards,
    loadingPaginatedReports,

  loadingEditRoleUsers,
  loadingEditRoleReports,
  loadingEditRoleDashboards,
  loadingEditRolePredicates,
  loadingFinancialYears,
  financialYears,
  loadingKpis,
  kpis,
  activeKpiId: activeKpi,
  activeKpiPredicateId: activeKpiPredicate,
  activeKpiFinancialYearId: activeKpiFinancialYear,
  activeKpiClientId: activeKpiClient,
  activeRoleClients,
  loadingRoleClients,
  activeRoleKpiGroupingPredicates,
  loadingRoleKpiGroupingPredicates,
  activeKpiEntries,
  loadingKpiEntries,
  activeDashboardDefaultClient,
  activeDashboardDefaultPredicateId
});
