import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { compose, createStore } from "redux";
import { initialiseAuth0 } from "./actions/auth0";
import App from "./App";
import "./assets/styles/index.css";
import Auth from "./auth/Auth";
import middleware from "./middleware";
import { allReducers } from "./reducers";
import registerServiceWorker from "./registerServiceWorker";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(allReducers, composeEnhancers(middleware));

const auth = new Auth();

store.dispatch(initialiseAuth0(auth));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root") as HTMLElement
);
// registerServiceWorker();
