import * as React from "react";
import Span from "../atoms/Span";

export interface ILoginButtonProps {
  login:()=> void;
  outline?: boolean;
}

const LoginButton = (props: ILoginButtonProps) => {
  const { login, outline } = props;
  return (
    <button
      onClick={login}
      className={
        outline ? "btn btn-outline-success btn-lg" : "btn btn-success btn-lg"
      }
    >
      <Span>Login</Span>
    </button>
  );
};

export default LoginButton;
