import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button } from "reactstrap";
import { clearActiveReport, setActiveReport } from "../../actions/activeReport";
import IRole from "../../models/IRole";
import ISqlReport from "../../models/ISqlReport";
import { IStore } from "../../models/IStore";
import { buildIframeUrl, buildRolesParams } from "../../utils";
import ActiveRoleBadge from "../molecules/ActiveRoleBadge";
// import SubscriptionModal from "../organisms/SubscriptionModal";
export interface IReportViewPageProps {
  report: ISqlReport;
  activeUserRole: IRole;
  onSetActiveReport: (id: number) => void;
  onClearActiveReport: () => void;
  roleCount: number;
}

export interface IReportViewPageState {
  height: string;
  iFrameUrl: string;
  width: string;
}

export class ReportViewPage extends React.Component<
  IReportViewPageProps,
  IReportViewPageState
> {
  public state: IReportViewPageState = {
    height: "",
    iFrameUrl: "",
    width: "",
  };
  constructor(props: IReportViewPageProps) {
    super(props);
    this.goFull = this.goFull.bind(this);
    this.exitHandler = this.exitHandler.bind(this);
  }
  public goFull = () => {
    const iframeContent = document.getElementById(
      "iFrameContent"
    ) as HTMLElement;
    iframeContent.requestFullscreen();
    this.setState({
      height: "calc(100vh)",
      width: "calc(100vw)",
    });
  };

  public exitHandler = () => {
    if (document.fullscreen !== null) {
      this.setState({
        height: "calc(85vh)",
        width: "calc(99vw)",
      });
    }
  };

  public async componentDidMount() {
    if (document.addEventListener) {
      document.addEventListener(
        "webkitfullscreenchange",
        this.exitHandler,
        false
      );
      document.addEventListener("mozfullscreenchange", this.exitHandler, false);
      document.addEventListener("fullscreenchange", this.exitHandler, false);
      document.addEventListener("MSFullscreenChange", this.exitHandler, false);
    }
    const { report, activeUserRole, onSetActiveReport } = this.props;
    onSetActiveReport(report.id);
    const roles = buildRolesParams(activeUserRole);
    this.setState({
      iFrameUrl: (await buildIframeUrl(report)).concat(roles),
    });
    this.setState({ width: "calc(99vw)", height: "calc(85vh)" });
  }

  public async componentDidUpdate(prevProps: IReportViewPageProps) {
    const { report, activeUserRole, onSetActiveReport } = this.props;
    if (prevProps.report.id !== report.id) {
      onSetActiveReport(report.id);
      const roles = buildRolesParams(activeUserRole);
      this.setState({
        iFrameUrl: (await buildIframeUrl(report)).concat(roles),
      });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener(
      "webkitfullscreenchange",
      this.exitHandler,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.exitHandler,
      false
    );
    document.removeEventListener("fullscreenchange", this.exitHandler, false);
    document.removeEventListener("MSFullscreenChange", this.exitHandler, false);
  }
  public render() {
    const { report, roleCount } = this.props;
    return (
      <React.Fragment>
        <span style={{ backgroundColor: "white" }}>
          Report: <strong>{report.displayName} </strong>
          {roleCount > 1 && (
            <span>
              {" "}
              using Role: <ActiveRoleBadge />
            </span>
          )}
        </span>
        {/* <div style={{ margin: 4, float: "right" }}>
          <SubscriptionModal />
        </div> */}
        <Button
          onClick={this.goFull}
          color="info"
          size="sm"
          style={{ margin: 4, float: "right" }}
        >
          Go Full Screen
        </Button>
        <iframe
          title="iFrameContent"
          id="iFrameContent"
          src={this.state.iFrameUrl}
          allowFullScreen={true}
          style={{
            backgroundColor: "#FFF",
            height: this.state.height,
            width: this.state.width,
          }}
          seamless={true}
          sandbox="allow-same-origin allow-forms allow-scripts allow-popups allow-downloads"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let report = {} as ISqlReport;
  if (match !== null) {
    report = state.userReports.find((rep) => {
      return rep.id === parseInt((match.params as any).id as string, 10);
    }) as ISqlReport;
    // if (report === undefined) {
    //   // tslint:disable-next-line:no-console
    //   console.log("didn't find the report");
    //   return { report: {} as ISqlReport };
    // }
  }
  return {
    activeUserRole: state.userRoles.find(
      (role) => role.id === state.activeRoleId
    ) as IRole,
    report,
    roleCount: state.userRoles.length,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onClearActiveReport: () => {
    dispatch(clearActiveReport());
  },
  onSetActiveReport: (id: number) => {
    dispatch(setActiveReport(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewPage);
