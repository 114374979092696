import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import paths from "../../constants";
import { IStore } from "../../models/IStore";
import Jumbotron from "../atoms/Jumbotron";
import Text from "../atoms/Text";
import LoginButton from "../molecules/LoginButton";

export interface ISplashPageProps extends RouteComponentProps {
  auth: Auth;
  isAuthenticated: boolean;
}

const SplashPage = (props: ISplashPageProps) => 
{
  const { auth, isAuthenticated, history } = props;
  if (isAuthenticated) {
    history.push(paths.home);
  }
  return (
    <div>
      {!isAuthenticated && (
        <React.Fragment>
          <Jumbotron>
            <Text>To get started you must</Text>
            <LoginButton
              outline={true}
              login={
                // tslint:disable-next-line:jsx-no-lambda
                () => {
                  auth.login();
                }
              }
            />
          </Jumbotron>
         
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0,
    isAuthenticated: state.auth0.isAuthenticated()
  };
};

export default connect(mapStateToProps)(SplashPage);
