import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "../../models/IStore";
import Text from "../atoms/Text";
import Avatar from "../molecules/Avatar";
export interface IProfileBarProps {
  name: string;
  picture: string;
}
const ProfileBar: React.SFC<IProfileBarProps> = (props: IProfileBarProps) => {
  const { name, picture } = props;
  return (
    <React.Fragment>
      <Avatar name={name} picture={picture} />
      <Text>{name}</Text>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStore) => {
  const names = state.auth0.getProfile().name.split(" ");
  return {
    name: names[0],
    picture: state.auth0.getProfile().picture
  };
};

export default connect(mapStateToProps)(ProfileBar);
