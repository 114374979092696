import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import paths from "../../constants";
import IPbiDashboard from "../../models/IPbiDashboard";
import { IStore } from "../../models/IStore";
import Text from "../atoms/Text";
import DashboardIcon from "../molecules/DashboardIcon";

export interface IDashboardDropDownMenuProps {
  dashboards: IPbiDashboard[];
}
export class DashboardDropDownMenu extends React.Component<
  IDashboardDropDownMenuProps
> {
  public state: any = {
    dropdownOpen: false
  };
  constructor(props: IDashboardDropDownMenuProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  public toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  public render() {
    const { dashboards } = this.props;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={this.state.dropdownOpen}
          style={{ cursor: "pointer" }}
        >
          <DashboardIcon />
          <Text style={{ paddingRight: "4em", color: "#FFF" }}>Dashboards</Text>
        </DropdownToggle>
        <DropdownMenu>
          {dashboards.map(dashboard => (
            <DropdownItem key={dashboard.id}>
              <Link to={paths.pbiDashboards.concat(dashboard.id.toString())}>
                {dashboard.displayName}
              </Link>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    dashboards: state.userDashboards
  };
};

export default connect(mapStateToProps)(DashboardDropDownMenu);
