import * as React from "react";
import Image from "../atoms/Image";
import Span from "../atoms/Span";
import ToolTip from "../atoms/ToolTip";

export interface IIconProps {
  id: string;
  icon: string;
  text: string;
  style?: React.CSSProperties;
  tooltip?: boolean;
}

const Icon: React.SFC<IIconProps> = (props: IIconProps) => {
  const { icon, id, style, text, tooltip } = props;
  return (
    <React.Fragment>
      <Span id={id}>
        <Image src={icon} alt={text} style={style} />
      </Span>
      {tooltip && (
        <ToolTip target={id}>
          <Span>{text}</Span>
        </ToolTip>
      )}
    </React.Fragment>
  );
};

export default Icon;
