import * as React from "react";
import Image from "../atoms/Image";

export interface IAvatarProps {
  height?: string;
  borderRadius?: number;
  name: string;
  picture: string;
}

const Avatar: React.SFC<IAvatarProps> = (props: IAvatarProps) => {
  const { height, borderRadius } = props;
  return (
    <React.Fragment>
      <Image
        src={props.picture}
        alt={props.name}
        style={{
          borderRadius: borderRadius === undefined ? 50 : borderRadius,
          height: height === undefined ? "50px" : height
        }}
      />
    </React.Fragment>
  );
};

export default Avatar;
