import ICurrencyCode from "../models/ICurrencyCode";
import { fetchSecureData } from "../utils";

export const getCurrencyCodes = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    "/api/CurrencyCode/GetCurrencyCodes",
    accessToken
  );
  const currencyCodes = ((await data.json()) as any).value as ICurrencyCode[];
  if (currencyCodes !== undefined) {
    return currencyCodes;
  } else {
    return [] as ICurrencyCode[];
  }
};

export default getCurrencyCodes;
