import IGroup from "../models/IGroup";
import { fetchSecureData } from "../utils";

export const saveUserGroup = async (
  accessToken: string,
  userId: number,
  groupId: number,
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/UserProfileGroup/Add?userId=${userId}&groupId=${groupId}`,
      accessToken
    );
    const userGroup = ((await data.json()) as any).value as IGroup;
    return userGroup;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/UserProfileGroup/Add?userId=${userId}&&groupId=${groupId}`
    );
  }
  return {} as IGroup;
};

export default saveUserGroup;
