import * as React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardFooter, Input } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import { handleSaveUserProfile } from "../../../actions/profiles";
import AddTextField from "../../../components/molecules/AddTextField";
import { settings } from "../../../constants";
import { IStore } from "../../../models/IStore";
import IUserProfile from "../../../models/IUserProfile";
import { validateEmail } from "../../../utils";

export interface IAddUserDetailsProps {
  accessToken: string;
  users: IUserProfile[];
  onHandleSaveUser: (
    accessToken: string,
    emailAddress: string,
    isEnabled: boolean
  ) => void;
}
export interface IAddUserDetailsState {
  emailAddress: string;
  isEnabled: boolean;
}

class AddUserDetails extends React.Component<
  IAddUserDetailsProps,
  IAddUserDetailsState
> {
  public state = {
    emailAddress: "",
    // tslint:disable-next-line:object-literal-sort-keys
    isEnabled: true
  };
  constructor(props: IAddUserDetailsProps) {
    super(props);
    this.save = this.save.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public readyToSave = () => {
    const { emailAddress } = this.state;
    if (
      emailAddress === undefined ||
      emailAddress === "" ||
      !validateEmail(emailAddress)
    ) {
      return false;
    }
    return true;
  };
  public save = (e: React.MouseEvent) => {
    const { accessToken } = this.props;
    const { emailAddress, isEnabled } = this.state;
    // do save
    if (this.readyToSave()) {
      this.props.onHandleSaveUser(accessToken, emailAddress, isEnabled);
    }

    // callback and do next
  };
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public render() {
    const { users } = this.props;
    const { allowDisableUsers } = settings;
    const { emailAddress, isEnabled } = this.state;
    return (
      <Card>
        <CardTitle>Add New User</CardTitle>
        <CardBody>
          <AddTextField
            noDuplicates={true}
            isValid={validateEmail}
            title="Email Address"
            value={emailAddress}
            existingValues={users.map(user => user.emailAddress)}
            // tslint:disable-next-line:jsx-no-lambda
            callback={(email: string) =>
              this.setState(() => ({ emailAddress: email }))
            }
          />
          {allowDisableUsers && (
            <tr>
              <th scope="row">Enabled</th>
              <td>
                <Input
                  type="checkbox"
                  defaultChecked={isEnabled}
                  onChange={this.isEnabledChange}
                />
                {isEnabled}
              </td>
            </tr>
          )}
        </CardBody>
        <CardFooter>
          {this.readyToSave() && (
            <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
              Save Changes
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    users: state.profiles
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleSaveUser: (
    accessToken: string,
    emailAddress: string,
    isEnabled: boolean
  ) => {
    dispatch(handleSaveUserProfile(accessToken, emailAddress, isEnabled));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserDetails);
