import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import IPredicate from "../../../models/IPredicate";
import { IStore } from "../../../models/IStore";
import { handleLoadPredicates } from "../../../actions/predicates";

export interface ISearchPredicatesPageProps {
  accessToken: string;
  onHandleLoadPredicates: (accessToken: string) => void;
  predicates: IPredicate[];
  predicatesLoaded: boolean;
}

export interface ISearchPredicatesPageState {
  filteredPredicates: IPredicate[];
  matchedNameExact: boolean;
  name: string;
  noMatchingNames: boolean;
}
class SearchPredicatesPage extends React.Component<
  ISearchPredicatesPageProps,
  ISearchPredicatesPageState
> {
  public isLoaded = false;
  public state = {
    filteredPredicates: [] as IPredicate[],
    matchedNameExact: false,
    name: "",
    noMatchingNames: false
  };
  constructor(props: ISearchPredicatesPageProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public handleInputChange(e: any): void {
    e.preventDefault();
    const { predicates } = this.props;

    const searchPredicateName = e.target.value.toLowerCase();
    if (!searchPredicateName) {
      this.setState({
        filteredPredicates: predicates
      });
    } else {
      const filteredPredicates: IPredicate[] = predicates.filter(
        (predicate: IPredicate) =>
          (predicate.name.toLowerCase().includes(searchPredicateName) ||
          predicate.displayName.toLowerCase().includes(searchPredicateName))
      );
      if (filteredPredicates.length === 1) {
        this.setState({ matchedNameExact: true, noMatchingNames: false });
      } else if (filteredPredicates.length === 0) {
        this.setState({ noMatchingNames: true, matchedNameExact: false });
      } else {
        this.setState({ matchedNameExact: false, noMatchingNames: false });
      }
      this.setState({
        filteredPredicates
      });
    }
  }

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadPredicates,
      predicates,
      predicatesLoaded
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!predicatesLoaded) {
        onHandleLoadPredicates(accessToken);
      }
      this.setState({ filteredPredicates: predicates });
    }
  }

  public componentDidUpdate(prevProps: ISearchPredicatesPageProps) {
    const { predicates } = this.props;
    if (prevProps.predicates !== predicates) {
      this.setState({
        filteredPredicates: predicates
      });
    }
  }

  public render() {
    const { predicates, predicatesLoaded } = this.props;
    const {
      noMatchingNames,
      matchedNameExact,
      name,
      filteredPredicates
    } = this.state;
    return (
      <React.Fragment>
        <h1 style={{ backgroundColor: "white" }}>Predicate Administration</h1>
        {!predicatesLoaded && <DataLoading dataTitle="Predicates" />}
        {predicatesLoaded && (
          <React.Fragment>
            <FormGroup>
              <Card>
                <CardTitle>
                  <Label for="name">Select Predicate To Edit</Label>
                </CardTitle>
                <CardBody tag="span">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Filter Predicate"
                    onChange={this.handleInputChange}
                    valid={matchedNameExact}
                    invalid={noMatchingNames}
                  />
                  <FormFeedback valid={true}>
                    {matchedNameExact && (
                      <span>
                        Predicate found! Select to edit
                        {name}
                      </span>
                    )}
                  </FormFeedback>
                  <FormFeedback invalid="true">
                    {noMatchingNames && (
                      <span>No Predicates match name entered </span>
                    )}
                  </FormFeedback>
                </CardBody>
              </Card>
            </FormGroup>
            <Card>
              <CardBody>
                {predicates.length > 0 &&
                  filteredPredicates.map((predicate: IPredicate) => (
                    <div key={predicate.id}>
                      <Link
                        to={paths.editPredicate.concat(predicate.id.toString())}
                      >
                        <span>{predicate.displayName}</span>
                      </Link>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    predicates: state.predicates,
    predicatesLoaded: state.loadingPredicates
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadPredicates: (accessToken: string) => {
    dispatch(handleLoadPredicates(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPredicatesPage);
