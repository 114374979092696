import * as React from "react";
import { Badge, Label } from "reactstrap";
import IRole from "../../models/IRole";
import { IStore } from "models/IStore";
import { connect } from "react-redux";
import { handleDeleteEditUserRoleId } from "actions/editUserRoleIds";

export interface IRolesBadgeProps {
  accessToken: string;
  roleIds: number[];
  roles: IRole[];
  userId: number;
  onHandleDeleteEditUserRoleId: (
    accessToken: string,
    userId: number,
    roleId: number
  ) => void;
}

class RolesBadge extends React.Component<IRolesBadgeProps> {
  constructor(props: IRolesBadgeProps) {
    super(props);
    this.removeUserRole = this.removeUserRole.bind(this);
  }
  public removeUserRole = async(roleId: number) => {
    const { accessToken, userId, onHandleDeleteEditUserRoleId } = this.props;
    await onHandleDeleteEditUserRoleId(accessToken, userId, roleId);
  }
  public render() {
    const { roleIds, roles } = this.props;
    return (
      <React.Fragment>
        <Label for="grpSelectedRoles">Currently Selected Roles:</Label>
        <div id="grpSelectedRoles">
          {roles.length > 0 &&
            roleIds.map(id => (
              <Badge
                key={id}
                style={{ margin: 4, padding: 4 }}
                color="info"
                pill={true}
              >
                {(roles.find(role => role.id === id) as IRole).displayName}{" "}
                <Badge
                  color="info"
                  pill={true}
                  href="#"
                  style={{ fontSize: "larger" }}
                  onClick={(e: any) => this.removeUserRole(id)}
                >
                  X
                </Badge>
              </Badge>
            ))}
        </div>
        {roleIds.length === 0 && <div>None</div>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    userId: state.editUserDetails.id
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleDeleteEditUserRoleId: (
    accessToken: string,
    userId: number,
    roleId: number
  ) => {
    dispatch(handleDeleteEditUserRoleId(accessToken, userId, roleId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesBadge);
