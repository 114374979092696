import * as React from "react";
import Col from "../atoms/Col";
import Row from "../atoms/Row";

export interface IPageHeaderProps {
  rowStyle?: React.CSSProperties;
  colStyle?: React.CSSProperties;
  children: React.ReactNode;
  rowId?: string;
  colId?: string;
}

const PageHeader: React.SFC<IPageHeaderProps> = (props: IPageHeaderProps) => {
  const { rowStyle, colStyle, children, rowId, colId } = props;
  return (
    <Row
      id={rowId}
      style={
        rowStyle === undefined
          ? {
              background: "linear-gradient(to right,#1d98a8,#4aaf75)",
              
            }
          : rowStyle
      }
    >
      <Col id={colId} style={colStyle}>
        {children}
      </Col>
    </Row>
  );
};

export default PageHeader;
