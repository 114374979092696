import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import paths from "../../constants";
import ISqlReport from "../../models/ISqlReport";
import { IStore } from "../../models/IStore";
import Text from "../atoms/Text";
import ReportIcon from "../molecules/ReportIcon";

export interface IReportDropDownMenuProps {
  reports: ISqlReport[];
}
export class ReportDropDownMenu extends React.Component<
  IReportDropDownMenuProps
> {
  public state: any = {
    dropdownOpen: false
  };
  constructor(props: IReportDropDownMenuProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  public toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  public render() {
    const { reports } = this.props;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={this.state.dropdownOpen}
          style={{ cursor: "pointer" }}
        >
          <ReportIcon />
          <Text style={{ paddingRight: "4em", color: "#FFF" }}>Reports</Text>
        </DropdownToggle>
        <DropdownMenu>
          {reports.map(report => (
            <DropdownItem key={report.id}>
              <Link to={paths.sqlReports.concat(report.id.toString())}>
                {report.displayName}
              </Link>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    reports: state.userReports
  };
};

export default connect(mapStateToProps)(ReportDropDownMenu);
