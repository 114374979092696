import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import {
  handleDeleteReport,
  handleLoadReports,
  handleUpdateReport
} from "../../../actions/reports";
import AddTextField from "../../../components/molecules/AddTextField";
import ReportDetailsTable from "../../../components/molecules/ReportDetailsTable";
import { paths, settings, zoomModes } from "../../../constants";
import ISqlReport from "../../../models/ISqlReport";
import { IStore } from "../../../models/IStore";
import {
  validateParametersText,
  validatePathText,
  validateReportDisplayName,
  validateReportName,
  validateZoomPercent
} from "../../../utils";
export interface IEditReportPageProps extends RouteComponentProps {
  accessToken: string;
  report: ISqlReport;
  existingReports: ISqlReport[];
  onHandleLoadReports: (accessToken: string) => void;
  onHandleUpdateReport: (accessToken: string, report: ISqlReport) => void;
  onHandleDeleteReport: (accessToken: string, id: number) => void;
}
export interface IEditReportPageState {
  editModeOn: boolean;
  displayName: string;
  name: string;
  path: string;
  parameters: string;
  defaultZoomMode: string;
  defaultZoomPercent: number;
  sqlReportingServerUrl: string;
  sqlReportingViewerUrl: string;
  isEnabled: boolean;
  showDeleteModal: boolean;
  isReadyToSave: boolean;
}

class EditReportPage extends React.Component<
  IEditReportPageProps,
  IEditReportPageState
> {
  public isLoaded = false;
  public state = {
    editModeOn: false,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: "",
    name: "",
    path: "",
    parameters: "",
    defaultZoomMode: "",
    defaultZoomPercent: 100,
    sqlReportingServerUrl: "",
    sqlReportingViewerUrl: "",
    isEnabled: true,
    showDeleteModal: false,
    isReadyToSave: false
  };
  constructor(props: IEditReportPageProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.delete = this.delete.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
    this.defaultZoomModeChange = this.defaultZoomModeChange.bind(this);
  }

  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public save = (e: React.MouseEvent) => {
    e.preventDefault();
    const { accessToken, onHandleUpdateReport, history, report } = this.props;
    const {
      name,
      displayName,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent,
      sqlReportingServerUrl,
      sqlReportingViewerUrl,
      isEnabled,
      isReadyToSave
    } = this.state;
    const updatedDashboard = {
      id: report.id,
      name,
      // tslint:disable-next-line:object-literal-sort-keys
      displayName,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent,
      sqlReportingServerUrl,
      sqlReportingViewerUrl,
      isEnabled
    } as ISqlReport;
    if (this.readyToSave() && isReadyToSave) {
      onHandleUpdateReport(accessToken, updatedDashboard);
      history.push(paths.home);
    }
  };
  public delete = (e: React.MouseEvent) => {
    const { accessToken, report, history, onHandleDeleteReport } = this.props;
    onHandleDeleteReport(accessToken, report.id);
    history.push(paths.home);
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public toggleEdit() {
    this.setState({ editModeOn: !this.state.editModeOn });
  }
  public toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  public readyToSave = () => {
    const {
      displayName,
      name,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent
    } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validateReportDisplayName(displayName)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (name === undefined || name === "" || !validateReportName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (path === undefined || path === "" || !validatePathText(path)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (parameters === undefined || !validateParametersText(parameters)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (
      defaultZoomMode === "Percent" &&
      (defaultZoomPercent === undefined ||
        !validateZoomPercent(defaultZoomPercent.toString()))
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public defaultZoomModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.setState(() => ({ isReadyToSave: true }));
    const defaultZoomMode =
      e.target.value === undefined ? "" : (e.target.value as string);
    switch (defaultZoomMode) {
      case "Whole Page":
        this.setState(() => ({ defaultZoomMode, defaultZoomPercent: 100 }));
        break;
      case "Page Width":
        this.setState(() => ({ defaultZoomMode, defaultZoomPercent: 100 }));
        break;
      case "Percent":
        this.setState(() => ({ defaultZoomMode, defaultZoomPercent: 100 }));
        break;
      case "500%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 500
        }));
        break;
      case "200%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 200
        }));
        break;
      case "150%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 150
        }));
        break;
      case "100%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 100
        }));
        break;
      case "75%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 75
        }));
        break;
      case "50%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 50
        }));
        break;
      case "25%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 25
        }));
        break;
      case "10%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 10
        }));
        break;
      default:
        this.setState(() => ({ defaultZoomMode }));
        break;
    }
  };
  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadReports,
      existingReports,
      report
    } = this.props;
    const {
      displayName,
      name,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent,
      isEnabled
    } = report;
    this.setState(() => ({
      displayName,
      name,
      path,
      // tslint:disable-next-line:object-literal-sort-keys
      parameters,
      defaultZoomMode,
      defaultZoomPercent,
      isEnabled
    }));
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingReports.length === 0) {
        onHandleLoadReports(accessToken);
      }
    }
  }
  public render() {
    const { report, existingReports } = this.props;
    const {
      editModeOn,
      displayName,
      name,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent,
      isEnabled,
      isReadyToSave
    } = this.state;
    const { allowDisableReports } = settings;
    return (
      <Form>
        <Modal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete Report {displayName}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete Report: {displayName}
            {"?"}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Confirm Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Card>
          <CardHeader>Report Details for {displayName}</CardHeader>
          <CardBody>
            {!editModeOn && <ReportDetailsTable report={report} />}
            {editModeOn && (
              <React.Fragment>
                <AddTextField
                  noDuplicates={true}
                  isValid={validateReportDisplayName}
                  title="Display Name"
                  value={displayName}
                  existingValues={existingReports.map(d => d.displayName)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (dn: string) => {
                    await this.setState(() => ({ displayName: dn }));
                    this.readyToSave();
                  }}
                />

                <AddTextField
                  noDuplicates={true}
                  isValid={validateReportName}
                  title="Name"
                  value={name}
                  existingValues={existingReports.map(d => d.name)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (n: string) => {
                    await this.setState(() => ({ name: n }));
                    this.readyToSave();
                  }}
                />
                <AddTextField
                  noDuplicates={false}
                  isValid={validatePathText}
                  title="Path"
                  value={path}
                  existingValues={existingReports.map(d => d.path)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (p: string) => {
                    await this.setState(() => ({ path: p }));
                    this.readyToSave();
                  }}
                />
                <AddTextField
                  allowEmptyValues={true}
                  noDuplicates={false}
                  isValid={validateParametersText}
                  title="Parameters"
                  value={parameters}
                  existingValues={existingReports.map(d => d.parameters)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (params: string) => {
                    await this.setState(() => ({ parameters: params }));
                    this.readyToSave();
                  }}
                />
                <FormGroup>
                  <Label for="defaultZoomMode">Default Zoom Mode</Label>
                  <Input
                    id="defaultZoomMode"
                    type="select"
                    placeholder="Default Zoom Mode"
                    value={defaultZoomMode}
                    onChange={this.defaultZoomModeChange}
                  >
                    {zoomModes.map(zm => (
                      <option key={zm} value={zm}>
                        {zm}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                {defaultZoomMode === "Percent" && (
                  <AddTextField
                    noDuplicates={false}
                    isValid={validateZoomPercent}
                    title="Zoom Percent"
                    value={
                      defaultZoomPercent !== undefined
                        ? defaultZoomPercent.toString()
                        : ""
                    }
                    existingValues={existingReports.map(d => d.path)}
                    // tslint:disable-next-line:jsx-no-lambda
                    callback={async (zpct: string) => {
                      await this.setState(() => ({
                        defaultZoomPercent: Number(zpct)
                      }));
                      this.readyToSave();
                    }}
                  />
                )}
                {allowDisableReports && (
                  <Table>
                    <tr>
                      <th scope="row">Enabled</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isEnabled}
                          onChange={this.isEnabledChange}
                        />
                        {isEnabled}
                      </td>
                    </tr>
                  </Table>
                )}
              </React.Fragment>
            )}
          </CardBody>
          <CardFooter>
            {!editModeOn && (
              <React.Fragment>
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  onClick={this.toggleDeleteModal}
                  style={{
                    margin: 4,
                    padding: 4
                  }}
                >
                  <strong>Delete Report</strong>
                </Button>
              </React.Fragment>
            )}
            {editModeOn && (
              <React.Fragment>
                {isReadyToSave && (
                  <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
                    Save Changes
                  </Button>
                )}
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let report = {} as ISqlReport;
  const existingReports = state.reports.filter(
    dsh => dsh.id !== parseInt((match.params as any).id as string, 10)
  );
  if (match !== null) {
    report = state.reports.find(dsh => {
      return dsh.id === parseInt((match.params as any).id as string, 10);
    }) as ISqlReport;
    // if (report === undefined) {
    //   // tslint:disable-next-line:no-console
    //   console.log("didn't find the report");
    //   return { report: {} as ISqlReport };
    // }
  }
  return {
    accessToken: state.auth0.accessToken,
    existingReports,
    report
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadReports: (accessToken: string) => {
    dispatch(handleLoadReports(accessToken));
  },
  onHandleUpdateReport: (accessToken: string, report: ISqlReport) => {
    dispatch(handleUpdateReport(accessToken, report));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleDeleteReport: (accessToken: string, id: number) => {
    dispatch(handleDeleteReport(accessToken, id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditReportPage);
