import * as React from "react";

export interface IJumbotronProps {
  fluid?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Jumbotron: React.SFC<IJumbotronProps> = (props: IJumbotronProps) => {
  const { children, fluid, style } = props;
  return (
    <div
      className={fluid ? "jumbotron jumbotron-fluid" : "jumbotron"}
      style={style === undefined ? { backgroundColor: "#000" } : style}
    >
      {children}
    </div>
  );
};

export default Jumbotron;
