import * as React from "react";
import { Badge, Label } from "reactstrap";
import IUserGroup from "../../models/IGroup";

export interface IGroupsBadgeProps {
  groupIds: number[];
  groups: IUserGroup[];
}

const GroupsBadge = (props: IGroupsBadgeProps) => {
  const { groupIds, groups } = props;
  return (
    <React.Fragment>
      <Label for="grpSelectedGroups">Currently Selected Groups:</Label>
      <div id="grpSelectedGroups">
        {groups.length > 0 &&
          groupIds.map(id => (
            <Badge key={id} style={{ margin: 4, padding: 4 }}>
              {(groups.find(role => role.id === id) as IUserGroup).name}
            </Badge>
          ))}
      </div>
      {groupIds.length === 0 && <div>None</div>}
    </React.Fragment>
  );
};

export default GroupsBadge;
