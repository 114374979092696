import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { clearEditRoleDetails } from "../../actions/editRoleDetails";
import { clearEditUserDetails } from "../../actions/editUserDetails";
import { IStore } from "../../models/IStore";
import paths, { kpiAdminGroupName, superAdminGroupName } from "../../constants";
import Text from "../atoms/Text";
import AdminIcon from "../molecules/AdminIcon";

export interface IAdminDropDownMenuProps extends RouteComponentProps {
  onHandleClearEditRole: () => void;
  onHandleClearEditUser: () => void;
  isKpiAdministrator: boolean;
  isSuperAdministrator: boolean;
}

class AdminDropDownMenu extends React.Component<IAdminDropDownMenuProps> {
  public state: any = {
    dropdownOpen: false
  };
  constructor(props: IAdminDropDownMenuProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  public toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  public render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={this.state.dropdownOpen}
          style={{ cursor: "pointer" }}
        >
          <AdminIcon />
          <Text style={{ paddingRight: "4em", color: "#FFF" }}>Admin</Text>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header={true}>Users</DropdownItem>
          <DropdownItem>
            <Link
              to={paths.quickAddUser}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={async (e: any) => {
                e.preventDefault();
                this.props.onHandleClearEditUser();
                this.props.history.push(paths.quickAddUser);
              }}
            >
              Quick Add New User(s)
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link
              to={paths.addUser}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={async (e: any) => {
                e.preventDefault();
                this.props.onHandleClearEditUser();
                this.props.history.push(paths.addUser);
              }}
            >
              New User Wizard (Detailed)
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={paths.searchUsers}>Search/Edit User</Link>
          </DropdownItem>
          <DropdownItem divider={true} />
          <DropdownItem header={true}>Roles</DropdownItem>
          <DropdownItem>
            <Link
              to={paths.addRoleWizard}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={async (e: any) => {
                e.preventDefault();
                this.props.onHandleClearEditRole();
                this.props.history.push(paths.addRoleWizard);
              }}
            >
              New Role Wizard
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={paths.searchRoles}>Search/Edit Roles</Link>
          </DropdownItem>
          <DropdownItem divider={true} />
          <DropdownItem header={true}>Reports</DropdownItem>
          <DropdownItem>
            <Link to={paths.addReport}>New Report</Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={paths.searchReports}>Search/Edit Report</Link>
          </DropdownItem>
          <DropdownItem divider={true} />
          <DropdownItem header={true}>Dashboards</DropdownItem>
          <DropdownItem>
            <Link to={paths.addDashboard}>New Dashboard</Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={paths.searchDashboards}>Search/Edit Dashboard</Link>
          </DropdownItem>
          <DropdownItem divider={true} />
          <DropdownItem header={true}>User Groups</DropdownItem>
          <DropdownItem>
            <Link to={paths.addGroup}>New User Group</Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={paths.searchGroups}>Search/Edit User Groups</Link>
          </DropdownItem>
          <DropdownItem divider={true} />
          <DropdownItem header={true}>Predicates</DropdownItem>
          <DropdownItem>
            <Link to={paths.addPredicate}>New Predicate</Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={paths.searchPredicates}>Search/Edit Predicates</Link>
          </DropdownItem>
          {this.props.isSuperAdministrator && (
            <React.Fragment>
              <DropdownItem divider={true} />
              <DropdownItem>
                <Link to={paths.addKpi}>Add KPI</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to={paths.searchKpis}>Search/Edit KPI</Link>
              </DropdownItem>
              <DropdownItem divider={true} />
              <DropdownItem>
                <Link to={paths.addFinancialYear}>Add Financial Year</Link>
              </DropdownItem>
            </React.Fragment>
          )}
          {this.props.isKpiAdministrator && (
            <React.Fragment>
              <DropdownItem>
                <Link to={paths.kpitargets}>Add KPI Targets</Link>
              </DropdownItem>
            </React.Fragment>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    isKpiAdministrator:
      state.userGroups.find(ug => ug.name === kpiAdminGroupName) !== undefined
        ? true
        : false,
    isSuperAdministrator:
      state.userGroups.find(ug => ug.name === superAdminGroupName) !== undefined
        ? true
        : false
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleClearEditRole: () => {
    dispatch(clearEditRoleDetails());
  },
  onHandleClearEditUser: () => {
    dispatch(clearEditUserDetails());
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminDropDownMenu)
);
