import * as React from "react";
import { RouteComponentProps } from "react-router";
import AddDashboardPage from "../components/pages/admin/AddDashboardPage";
import AddFinancialYearPage from "../components/pages/admin/AddFinancialYearPage";
import AddGroupPage from "../components/pages/admin/AddGroupPage";
import AddKpiPage from "../components/pages/admin/AddKpiPage";
import AddPredicatePage from "../components/pages/admin/AddPredicatePage";
import AddReportPage from "../components/pages/admin/AddReportPage";
import AddUserPage from "../components/pages/admin/AddUserPage";
import EditDashboardPage from "../components/pages/admin/EditDashboardPage";
import EditGroupPage from "../components/pages/admin/EditGroupPage";
import EditKpiPage from "../components/pages/admin/EditKpiPage";
import EditPredicatePage from "../components/pages/admin/EditPredicatePage";
import EditReportPage from "../components/pages/admin/EditReportPage";
import RoleWizardPage from "../components/pages/admin/RoleWizardPage";
import SearchDashboardsPage from "../components/pages/admin/SearchDashboardsPage";
import SearchGroupsPage from "../components/pages/admin/SearchGroupsPage";
import SearchKpisPage from "../components/pages/admin/SearchKpisPage";
import SearchPredicatesPage from "../components/pages/admin/SearchPredicatesPage";
import SearchReportsPage from "../components/pages/admin/SearchReportsPage";
import SearchRolesPage from "../components/pages/admin/SearchRolesPage";
import SearchUsersPage from "../components/pages/admin/SearchUsersPage";
import UserWizardPage from "../components/pages/admin/UserWizardPage";
import KpiTargetsPage from "../components/pages/KpiTargetsPage";
import Callback from "../components/pages/Callback";
import CallbackSaml from "../components/pages/CallbackSaml";
import CallbackSamlNA from "../components/pages/CallbackSamlNA";
import CallbackSamlProdAu from "../components/pages/CallbackSamlProdAu";
import CallbackSamlProdNA from "../components/pages/CallbackSamlProdNA";
import CallbackSamlDevAu from "../components/pages/CallbackSamlDevAu";
import CallbackSamlProdAsia from "../components/pages/CallbackSamlProdAsia";

import CallbackSamlPrtlDevAu from "../components/pages/CallbackSamlPrtlDevAu";
import CallbackSamlPrtlQaAu from "../components/pages/CallbackSamlPrtlQaAu";
import CallbackSamlPrtlProdAu from "../components/pages/CallbackSamlPrtlProdAu";


import DashboardSelectionPage from "../components/pages/DashboardSelectionPage";
import ReportViewPage from "../components/pages/ReportViewPage";
import SplashPage from "../components/pages/SplashPage";
import WelcomePage from "../components/pages/WelcomePage";
import TopNavBar from "../components/templates/TopNavBar";
import { paths } from "../constants";

export const routes = [
  {
    exact: true,
    main: (props: RouteComponentProps) => <Callback {...props} />,
    path: paths.callback,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <CallbackSaml {...props} />,
    path: paths.ssosaml,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <CallbackSaml {...props} />,
    path: paths.ssosamlqa,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <CallbackSamlNA {...props} />,
    path: paths.ssosamlqana,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <CallbackSamlProdAu {...props} />,
    path: paths.ssosamlprodau,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <CallbackSamlProdNA {...props} />,
    path: paths.ssosamlprodna,
    topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlProdAsia {...props} />,
        path: paths.ssosamlprodas,
        topbar: () => <TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlPrtlDevAu {...props} />,
        path: paths.ssosamldevprtlau,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlPrtlQaAu {...props} />,
        path: paths.ssosamlqaprtlau,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlPrtlProdAu {...props} />,
        path: paths.ssosamlprodprtlau,
        topbar: () => <TopNavBar />
    },






  {
    exact: true,
    main: (props: RouteComponentProps) => <CallbackSamlProdNA {...props} />,
    path: paths.ssosamldevau,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <SplashPage {...props} />,
    path: paths.root,
    topbar: () => <TopNavBar />
  }
];

export const secureRoutes = [
  {
    exact: true,
    main: (props: RouteComponentProps) => <DashboardSelectionPage {...props} />,
    path: `${paths.pbiDashboards}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <ReportViewPage {...props} />,
    path: `${paths.sqlReports}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <WelcomePage />,
    path: paths.home,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <KpiTargetsPage {...props} />,
    path: paths.kpitargets,
    topbar: () => <TopNavBar />
  }
];
export const secureAdminRoutes = [
  {
    exact: true,
    main: (props: RouteComponentProps) => <AddDashboardPage {...props} />,
    path: `${paths.addDashboard}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <AddReportPage {...props} />,
    path: `${paths.addReport}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <RoleWizardPage {...props} />,
    path: `${paths.addRoleWizard}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <AddUserPage {...props} />,
    path: `${paths.quickAddUser}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <UserWizardPage {...props} />,
    path: `${paths.addUser}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <AddGroupPage {...props} />,
    path: `${paths.addGroup}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <AddKpiPage {...props} />,
    path: `${paths.addKpi}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <AddFinancialYearPage {...props} />,
    path: `${paths.addFinancialYear}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <AddPredicatePage {...props} />,
    path: `${paths.addPredicate}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <SearchDashboardsPage />,
    path: `${paths.searchDashboards}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <SearchReportsPage />,
    path: `${paths.searchReports}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <SearchRolesPage />,
    path: `${paths.searchRoles}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <SearchUsersPage />,
    path: `${paths.searchUsers}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <SearchGroupsPage />,
    path: `${paths.searchGroups}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <SearchKpisPage />,
    path: `${paths.searchKpis}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: () => <SearchPredicatesPage />,
    path: `${paths.searchPredicates}`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <EditDashboardPage {...props} />,
    path: `${paths.editDashboard}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <EditReportPage {...props} />,
    path: `${paths.editReport}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <RoleWizardPage {...props} />,
    path: `${paths.editRole}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <UserWizardPage {...props} />,
    path: `${paths.editUser}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <EditGroupPage {...props} />,
    path: `${paths.editGroup}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <EditPredicatePage {...props} />,
    path: `${paths.editPredicate}:id`,
    topbar: () => <TopNavBar />
  },
  {
    exact: true,
    main: (props: RouteComponentProps) => <EditKpiPage {...props} />,
    path: `${paths.editKpi}:id`,
    topbar: () => <TopNavBar />
  }
];
