import * as React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { IStore } from "../../models/IStore";
import UserDashboardsCard from "../molecules/UserDashboardsCard";
import UserPaginatedReportsCard from "../molecules/UserPaginatedReportsCard";
import UserReportsCard from "../molecules/UserReportsCard";
import WelcomeCard from "../molecules/WelcomeCard";
import DataLoading from "../organisms/DataLoading";

export interface IWelcomePageProps {
  loaded: boolean;
  groupsLoaded: boolean;
  currencyCodesLoaded: boolean;
  fyStartMonthsLoaded: boolean;
  predicatesLoaded: boolean;
  userRolesLoaded: boolean;
  userDashboardsLoaded: boolean;
  //userPaginatedReportsLoaded: boolean;
  userReportsLoaded: boolean;
  userGroupsLoaded: boolean;
}

export const WelcomePage: React.SFC<IWelcomePageProps> = (
  props: IWelcomePageProps
) => {
  const {
    loaded,
    groupsLoaded,
    currencyCodesLoaded,
    fyStartMonthsLoaded,
    predicatesLoaded,
    userRolesLoaded,
    userDashboardsLoaded,
   // userPaginatedReportsLoaded,
    userReportsLoaded,
    userGroupsLoaded
  } = props;
  return (
    <Container>
      <Row>
        <Col>{!userRolesLoaded && <DataLoading dataTitle="User Roles" />}</Col>
      </Row>
      <Row>
        <Col>
          {!userDashboardsLoaded && <DataLoading dataTitle="User Dashboards" />}
        </Col>
          </Row>       
      <Row>
        <Col>
          {!userReportsLoaded && <DataLoading dataTitle="User Reports" />}
        </Col>
      </Row>
      <Row>
        <Col>
          {!userGroupsLoaded && <DataLoading dataTitle="User Groups" />}
        </Col>
      </Row>
      <Row>
        <Col>{!predicatesLoaded && <DataLoading dataTitle="Predicates" />}</Col>
      </Row>
      <Row>
        <Col>{!groupsLoaded && <DataLoading dataTitle="Groups" />}</Col>
      </Row>
      <Row>
        <Col>
          {!currencyCodesLoaded && <DataLoading dataTitle="Currency Codes" />}
        </Col>
      </Row>
      <Row>
        <Col>
          {!fyStartMonthsLoaded && (
            <DataLoading dataTitle="Financial Year Start Months" />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {loaded && <WelcomeCard />}{" "}
          {!loaded && <DataLoading dataTitle="Remaining" />}
        </Col>
      </Row>
      <Row>
              <Col>{userDashboardsLoaded && loaded && <UserDashboardsCard />}</Col>
          
              <Col>{userReportsLoaded && loaded && <UserReportsCard />}</Col>              
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    loaded: state.loading,
    // tslint:disable-next-line:object-literal-sort-keys
    groupsLoaded: state.loadingGroups,
    currencyCodesLoaded: state.loadingCurrencyCodes,
    fyStartMonthsLoaded: state.loadingFyStartMonths,
    predicatesLoaded: state.loadingPredicates,
    userRolesLoaded: state.loadingUserRoles,
    userDashboardsLoaded: state.loadingUserDashboards,
 
    userReportsLoaded: state.loadingUserReports,
    userGroupsLoaded: state.loadingUserGroups
  };
};

export default connect(mapStateToProps)(WelcomePage);
