import * as React from "react";
import { Router } from "react-router";
import PageBody from "./components/templates/PageBody";
import PageBodyRoutes from "./components/templates/PageBodyRoutes";
import PageContainer from "./components/templates/PageContainer";
import PageHeader from "./components/templates/PageHeader";
import PageHeaderRoutes from "./components/templates/PageHeaderRoutes";
import history from "./routes/history";

class App extends React.Component {
  public render() {
    return (
      <Router history={history}>
        <PageContainer>
          <PageHeader>
            <PageHeaderRoutes />
          </PageHeader>
          <PageBody>
            <PageBodyRoutes />
          </PageBody>
        </PageContainer>
      </Router>
    );
  }
}

export default App;
