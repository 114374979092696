import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Auth from "../../auth/Auth";
import paths, { adminGroupName, kpiAdminGroupName } from "../../constants";
import IGroup from "../../models/IGroup";
import { IStore } from "../../models/IStore";
import Navbar from "../atoms/Navbar";
import NavLink from "../atoms/NavLink";
import NavListItem from "../atoms/NavListItem";
import NavListLeft from "../atoms/NavListLeft";
import NavListRight from "../atoms/NavListRight";
import Span from "../atoms/Span";
import Text from "../atoms/Text";
import KpiIcon from "../molecules/KpiIcon";
import LoginButton from "../molecules/LoginButton";
import Logo from "../molecules/Logo";
import LogoutButton from "../molecules/LogoutButton";
import ActiveRoleDropDownMenu from "../organisms/ActiveRoleDropDownMenu";
import AdminDropDownMenu from "../organisms/AdminDropDownMenu";
import DashboardDropDownMenu from "../organisms/DashboardDropDownMenu";
import ProfileModal from "../organisms/ProfileModal";
import ReportDropDownMenu from "../organisms/ReportDropDownMenu";
import SupportModal from "../organisms/SupportModal";

export interface ITopNavBarProps {
  auth: Auth;
  isAuthenticated: boolean;
  isAdministrator: boolean;
  isKpiAdministrator: boolean;
  hasMultipleRoles: boolean;
}

const TopNavBar: React.SFC<ITopNavBarProps> = (props: ITopNavBarProps) => {
  const {
    isAuthenticated,
    isAdministrator,
    isKpiAdministrator,
    auth,
    hasMultipleRoles
  } = props;
  return (
    <React.Fragment>
      <Navbar>
        <Logo to={paths.root} />
        <NavListLeft>
          {isAuthenticated && hasMultipleRoles && (
            <NavListItem>
              
                <ActiveRoleDropDownMenu />

            </NavListItem>
          )}
          {isAuthenticated && (
            <React.Fragment>
              <NavListItem>
                <DashboardDropDownMenu />
              </NavListItem>
              <NavListItem>
                <ReportDropDownMenu />
              </NavListItem>
            </React.Fragment>
          )}
          {isAuthenticated && isAdministrator && (
            <NavListItem>
              <AdminDropDownMenu />
            </NavListItem>
          )}
          {isAuthenticated && isKpiAdministrator && (
            <NavListItem>
              <Link to={paths.kpitargets}>
                <KpiIcon />
                <Text style={{ color: "#FFF" }}>KPIs</Text>
              </Link>
            </NavListItem>
          )}
        </NavListLeft>

        <NavListRight>
          {isAuthenticated && (
            <React.Fragment>
              <NavListItem>
                <NavLink to="#">
                  <ProfileModal />
                </NavLink>
              </NavListItem>
            </React.Fragment>
          )}

          <NavListItem>
            <NavLink to="#">
              <SupportModal />
            </NavLink>
          </NavListItem>
        </NavListRight>
        <Span className="d-none d-lg-inline-block mb-3 mb-md-0 ml-md-3">
          {isAuthenticated && (
            <React.Fragment>
              <LogoutButton
                // tslint:disable-next-line:jsx-no-lambda
                logout={() => {
                  auth.logout();
                }}
              />
            </React.Fragment>
          )}
          {!isAuthenticated && (
            <LoginButton
              // tslint:disable-next-line:jsx-no-lambda
              login={() => auth.login()}
            />
          )}
        </Span>
      </Navbar>
    </React.Fragment>
  );
};
const mapStateToProps = (state: IStore) => {
  const adminGroup = (state.userGroups as IGroup[]).find(
    ug => ug.name === adminGroupName
  );
  const kpiAdminGroup = (state.userGroups as IGroup[]).find(
    ug => ug.name === kpiAdminGroupName
  );
  return {
    auth: state.auth0,
    isAuthenticated: state.auth0.isAuthenticated(),
    // tslint:disable-next-line:object-literal-sort-keys
    isAdministrator: adminGroup !== undefined,
    isKpiAdministrator: kpiAdminGroup !== undefined,
    hasMultipleRoles: state.userRoles.length > 1 ? true : false
  };
};
export default connect(mapStateToProps)(TopNavBar);
