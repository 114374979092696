export const AUTH_CONFIG = {
    audience: "https://app.ctmsmartdata.com/api/v3",
    clientId: "eZp9CNAr0RxENgzaaD5pSjQf3CfZfOmx",
    domain: "ctmsmartdata.au.auth0.com",
    scope: "openid email profile access:ctmsmartdata",

    samlConnectionProdAsia: "portal-as-prod-idp", //For CTM portal Asia PROD

    samlConnectionNA: "portal-na-stage-idp", //For North America STAGE
    samlConnectionProdNA: "portal-na-prod-idp", //For North America PROD

    samlConnectionDevAU: "portal-au-dev-idp", //For old CTM portal AU DEV
    samlConnection: "portal-qa-idp", //For old CTM portal AU qa ???
    samlConnectionProdAu: "portal-prod-idp", //For old CTM portal AU?


    //For new CTM portal AU:
    samlConnectionPrtlProdAu: "ctm-portal-au-prod-idp",
    samlConnectionPrtlQaAu: "ctm-portal-au-qa-idp",
    samlConnectionPrtlDevAu: "ctm-portal-au-dev-idp"


};
